import { useEffect, useMemo, useState } from "react";
import SessionDetails from "../../Containers/Session/SessionDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { placeholder } from "@babel/types";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  sessionPostAPI,
  sessionTypeAPI,
} from "../../Redux/features/SessionSlice";
import {
  CategoryTypeListAPI,
  ObjectListAPI,
  SimpleObjectListAPI,
} from "../../Redux/features/ObjectSlice";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { notifyError } from "../../helper";
import SessionObject from "../../Containers/Session/SessionObject";
import { getRecallCards } from "../../Redux/features/RecallCardsSlice";
import OverviewDetails from "../../Containers/Session/OverviewDetails";

type Props = {};

export interface SessionAddFormValues {
  sessionType: string;
  categoryType: string;
  sessionName: string;
  order: string;
  //headline: string;
  description: string;
  text: string;
  internal_session_name?: string;
  recall_cards: Array<any>;
  overview: {
    headline: string;
    text: string;
    time: string;
    is_equipments_needed: number;
    equipments: string;
    move_drills_count: number;
    recall_cards_count: number;
  };
}

const AddSessionPage = (props: Props) => {
  const [objectList, setObjectList] = useState<
    Array<{
      object_id: string;
      permission: 0 | 1 | "";
      time: number;
      title: string;
      description: string;
    }>
  >([]);

  const validationSchema = Yup.object().shape({
    sessionType: Yup.string().required("Session Type Required"),
    categoryType: Yup.string().required("Category Type Required"),
    sessionName: Yup.string().required("Session Name Required"),
    internalSessionName: Yup.string(),
    description: Yup.string(),
    text: Yup.string(),
    // headline: Yup.string().required("Headline Required"),
    overview: Yup.object().shape({
      headline: Yup.string().required("Overview Headline Required"),
      text: Yup.string(),
      equipments: Yup.string(),
    }),
    recall_cards: Yup.array(),
  });

  const allObjects = useAppSelector((state) => state.Object.objectlist);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(sessionTypeAPI());
    dispatch(CategoryTypeListAPI());
    dispatch(SimpleObjectListAPI());
    dispatch(getRecallCards({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const location = useLocation();

  console.log(
    allObjects
      .map((item1) => {
        const matchedItem = objectList?.find(
          (item2) => item2?.object_id === item1?._id
        );
        if (matchedItem) {
          return {
            ...matchedItem,
            ...item1,
          };
        }
        return null;
      })
      ?.filter((item) => item),
    "SSSSS"
  );
  console.log(location);
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sessionType: location.state?.session_type_id,
      categoryType: location.state?.category_type_id,
      sessionName: location.state?.session_name,
      // headline: location.state?.headline,
      internal_session_name: location.state?.internal_session_name,
      description: location.state?.description,
      text: location.state?.text,
      overview: {
        text: location.state?.overview?.text,
        headline: location.state?.overview?.headline,
        time: location.state?.overview?.time,
        is_equipments_needed: location.state?.overview?.equipments,
        equipments: location.state?.overview?.equipments,
        recall_cards_count: location.state?.recall_cards?.length || 0,
        // list_items: [],
      },
      recall_cards: location.state?.recall_cards,
    },
  });

  const formValues = watch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.Session.sessionPostAPIIdle);
  console.log(formValues, objectList);
  console.log(errors);
  const onSubmit = (data: SessionAddFormValues) => {
    let hasNoEmptyString = objectList.every((item) => item.object_id !== "");
    let hasNoPermission = objectList.every((item) => item.permission !== "");

    if (hasNoEmptyString && objectList.length > 0 && hasNoPermission) {
      dispatch(
        sessionPostAPI({
          session_type_id: data.sessionType,
          category_type_id: data.categoryType,
          session_name: data.sessionName,
          //  headline: data.headline,
          internal_session_name: data.internal_session_name,
          description: data.description,
          text: data.text,
          overview: {
            text: data.overview.text,
            headline: data.overview.headline,
            time: 0,
            is_equipments_needed:
              data.overview.equipments !== "" ? true : false,
            equipments: data.overview.equipments,
            recall_cards_count: data.recall_cards?.length || 0,
            move_drills_count: allObjects
              .map((item1) => {
                const matchedItem = objectList?.find(
                  (item2) => item2?.object_id === item1?._id
                );
                if (matchedItem) {
                  return {
                    ...matchedItem,
                    ...item1,
                  };
                }
                return null;
              })
              ?.filter((item) => item)
              .reduce((acc: any, item: any) => acc + Number(item?.reps), 0),
            list_items: objectList.map((items) => {
              return {
                title: items.title,
                description: items.description,
                time: items.time,
              };
            }),
          },
          session_object: objectList.map((items) => {
            return {
              time: items.time,
              permission: items.permission,
              object_id: items.object_id,
            };
          }),
          recall_cards: data.recall_cards?.map((i) => {
            return { recall_card_id: i.value };
          }),
        })
      ).then((res) => {
        navigate("/contentrepository/session");
      });
    } else {
      notifyError("Please Add At least 1 Object");
    }
  };

  useEffect(() => {
    setObjectList(
      location?.state?.object_list?.map((item: any, index: any) => {
        if (Array.isArray(location?.state?.overview?.list_items)) {
          return { ...item, ...location?.state?.overview?.list_items[index] };
        } else {
          return { ...item };
        }
      })
    );
  }, [location.state]);

  useEffect(() => {
    if (formValues.categoryType && objectList?.length) {
      setObjectList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.categoryType]);

  const AllRecallCards = useAppSelector(
    (state) => state.RecallCards.recallCards
  );

  useEffect(() => {
    setValue(
      "recall_cards",
      AllRecallCards.map((item) => {
        const recallCardId = item._id;
        const recallCard = location.state?.recall_cards.find(
          (card: any) => card.recall_card_id === recallCardId
        );

        if (recallCard) {
          return {
            value: recallCard.recall_card_id,
            label: item.question_text,
          };
        }

        return null;
      }).filter((item) => item !== null)
    );
  }, [AllRecallCards]);

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    dispatch(SimpleObjectListAPI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "FISIO | Add Session";
  }, []);

  return (
    <BoxLayout sectionName="Add Session" HeaderName="Content Repository">
      <form
        className="flex flex-col h-[calc(100vh-6.5rem-143.6px)] justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-4 border-t p-4">
          <div className="rounded-[10px] w-full border">
            <p className=" p-4 text-base font-semibold border-b">
              Session Details
            </p>
            <SessionDetails
              register={register}
              errors={errors}
              formValues={formValues}
              control={control}
            />
          </div>

          <SessionObject
            ObjectList={objectList}
            setObjectList={setObjectList}
            register={register}
            control={control}
            errors={errors}
            formValues={formValues}
            placeholder={placeholder}
          />

          <OverviewDetails
            ObjectList={objectList}
            control={control}
            errors={errors}
            formValues={formValues}
            placeholder={placeholder}
            register={register}
            setObjectList={setObjectList}
          />
        </div>
        <div className=" py-3 px-4 col-span-12 grid grid-cols-12">
          <button
            type="button"
            onClick={() => navigate("/contentrepository/session")}
            className="w-full col-start-1 text-[#1F3161]  items-center col-span-1 text-start flex"
          >
            <img src="/images/arrow-left.svg" alt="" />
            <span className="px-4">Back</span>
          </button>
          <ButtonComponent
            key={1}
            buttonType="submit"
            className="col-span-1   col-end-13"
            CTA="Save"
            varient="blue"
            loading={loading}
          />
        </div>
      </form>
    </BoxLayout>
  );
};

export default AddSessionPage;

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the App Control page
import AppControl from "../../Pages/AppControl/AppControl";

/**
 * App Control Routes
 * Defines the routes for managing the App Control module.
 *
 * Features:
 * 1. Includes routes for managing app control functionality.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const appControlRoutes = [
  {
    id: "ac1", // Unique route identifier
    path: "/appcontrol", // URL path for the App Control page
    name: "App Control", // Display name for the route
    module: "AppControl", // Associated module name
    component: AppControl, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "App Control", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the recall card-related pages
import AddRecallCard from "../../Pages/RecallCards/AddRecallCard";
import EditRecallCard from "../../Pages/RecallCards/EditRecallCard";
import RecallCard from "../../Pages/RecallCards/RecallCardList";

/**
 * Recall Card Routes
 * Defines the routes for managing recall cards in the Content Repository module.
 *
 * Features:
 * 1. Includes routes for viewing, adding, and editing recall cards.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const recallCardRoutes = [
  {
    id: "recall1", // Unique route identifier
    name: "Recall Card", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/recallcard", // URL path for the recall card list route
    component: RecallCard, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Recall Card", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "recall2", // Unique route identifier
    name: "Add Recall Card", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/addrecallcard", // URL path for adding a recall card
    component: AddRecallCard, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Recall Card", // Parent breadcrumb
        path: "/contentrepository/recallcard", // Path for breadcrumb
      },
      {
        name: "Add Recall Card", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "recall3", // Unique route identifier
    name: "Edit Recall Card", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/editrecallcard", // URL path for editing a recall card
    component: EditRecallCard, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Recall Card", // Parent breadcrumb
        path: "/contentrepository/recallcard", // Path for breadcrumb
      },
      {
        name: "Edit Recall Card", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

import {
  AsyncThunkOptions,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { PaginationState } from "@tanstack/react-table";
import { ApiResponse } from "../../types/apiresponse";

export const UserListAPI = createAsyncThunk<
  UserTypeListResponse,
  UserTypePayload,
  {}
>("UserManagement/UserList", async ({ per_page = 50, ...UserTypePayload }) => {
  const res = await axiosClient.get<UserTypeListResponse>(
    `auth/get-admin-list?page=${UserTypePayload.page}&type=${
      UserTypePayload.userType
    }${
      UserTypePayload.searchValue
        ? `&filteredVal=${UserTypePayload.searchValue}`
        : ""
    }${
      UserTypePayload.sortType === "ascending"
        ? `&sort=-${UserTypePayload.sortValue}`
        : ``
    }${
      UserTypePayload.sortType === "descending"
        ? `&sort=${UserTypePayload.sortValue}`
        : ``
    }${per_page ? `&per_page=${per_page}` : ``}
    ${
      UserTypePayload?.filter_dashboard !== "" &&
      UserTypePayload.filter_dashboard
        ? `&filter_dashboard=${UserTypePayload.filter_dashboard}`
        : ``
    }
    `
  );
  return res.data;
});
export const FreeUserListAPI = createAsyncThunk<
  FreeUserListResponse,
  FreeUserListPayload,
  {}
>(
  "UserManagement/FreeUserList",
  async ({ per_page = 50, ...UserTypePayload }) => {
    const res = await axiosClient.get<FreeUserListResponse>(
      `auth/freeUser?page=${UserTypePayload.page}&type=${
        UserTypePayload.userType
      }${
        UserTypePayload.searchValue
          ? `&filteredVal=${UserTypePayload.searchValue}`
          : ""
      }${
        UserTypePayload.sortType === "ascending"
          ? `&sort=-${UserTypePayload.sortValue}`
          : ``
      }${
        UserTypePayload.sortType === "descending"
          ? `&sort=${UserTypePayload.sortValue}`
          : ``
      }${per_page ? `&per_page=${per_page}` : ``}
    ${
      UserTypePayload?.filter_dashboard !== "" &&
      UserTypePayload.filter_dashboard
        ? `&filter_dashboard=${UserTypePayload.filter_dashboard}`
        : ``
    }
    `
    );
    return res.data;
  }
);

export const UserPostAPI = createAsyncThunk<any, UserCreatePostAPIRequest>(
  "UserManagement/PostUser",
  async (data) => {
    console.log();
    let url = data.id !== undefined ? `auth/users/${data.id}` : `auth/register`;
    try {
      const res = await axiosClient.post<UserPostAPIResponse>(url, data.data);
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const SwitchWeeklyRating = createAsyncThunk<
  {
    id?: string | number;
    new_value: boolean;
  },
  UserCreatePostAPIRequest
>("UserManagement/Switchuser", async (data) => {
  const res = await axiosClient.post<
    ApiResponse<{ weekly_rating_switch: boolean }>
  >(`auth/weekly-rating-switch/${data.id}`, data.data);
  return {
    id: data.id,
    new_value: res.data.data.weekly_rating_switch,
  };
});

export const DeleteUserAPI = createAsyncThunk<DeleteUserAPIResponse, number>(
  "UserManagement/DeleteUser",
  async (data) => {
    const res = await axiosClient.delete<DeleteUserAPIResponse>(
      `auth/users/${data}`
    );
    return res.data;
  }
);
export const AddFreeUserAPI = createAsyncThunk<
  ApiResponse<any>,
  { email: string }
>("UserManagement/FreeUser", async (data) => {
  const res = await axiosClient.post<ApiResponse<any>>(`auth/freeUser`, data);
  return res.data;
});
export const AllFreeUserAPI = createAsyncThunk<
  DeleteUserAPIResponse,
  "false" | "true"
>("UserManagement/ALLFreeUASer", async (data) => {
  const res = await axiosClient.post<DeleteUserAPIResponse>(
    `auth/payment-setting/${data}`
  );
  return res.data;
});
export const updateFTUE = createAsyncThunk<any, { status: boolean }>(
  "UserManagement/updateFTUE",
  async (data) => {
    const res = await axiosClient.post<any>(
      `auth/flow-setting/${data.status}`,
      data
    );
    return res.data;
  }
);

export const UserDetailsAPI = createAsyncThunk<
  UserDetailsAPIResponse,
  string,
  {}
>("UserManagement/getDetailsbyID", async (id) => {
  const res = await axiosClient.get<UserDetailsAPIResponse>(
    `auth/profile-detail/${id}`
  );
  return res.data;
});
export const UserDashboardAPI = createAsyncThunk<
  UserDashboardAPIResponse,
  void
>("UserManagement/userDashboard", async () => {
  const res = await axiosClient.get<UserDashboardAPIResponse>(
    `auth/user-dashboard`
  );
  return res.data;
});

// export const getAllUSerListsAPI = createAsyncThunk<
//   AllUserListApiResponse,
//   void
// >("UserManagement/AllUserList", async (page) => {
//   const res = await axiosClient.get<AllUserListApiResponse>(
//     `auth/all-user-progress-tracking/page=${page}?per_page=50`
//   );
//   return res.data;
// });
// export const getAllUserListsAPI = createAsyncThunk<
//   AllUserListApiResponse,
//   AllUserTypePayload,
//   {}
// >("UserManagement/AllUserList", async ({ per_page = 50, page_number }) => {
//   /*  const res = await axiosClient.get<AllUserListApiResponse>(
//     `auth/all-user-progress-tracking?page${50 ? `&page_number=${2}` : ""}`
//   ); */

//   const res = await axiosClient.get<AllUserListApiResponse>(
//     `auth/all-user-progress-tracking?page=${
//       page_number ? page_number : ""
//     }&per_page=${per_page}`

//   );
//   return res.data;
// });
export const getAllUserListsAPI = createAsyncThunk<
  AllUserListApiResponse,
  AllUserTypePayload,
  {}
>(
  "UserManagement/AllUserList",
  async ({ per_page = 50, ...AllUserTypePayload }) => {
    const res = await axiosClient.get<AllUserListApiResponse>(
      `auth/all-user-progress-tracking?page=${AllUserTypePayload.page}&type=${
        AllUserTypePayload.userType
      }${
        AllUserTypePayload.searchValue
          ? `&filteredVal=${AllUserTypePayload.searchValue}`
          : ""
      }${
        AllUserTypePayload.sortType === "ascending"
          ? `&sort=-${AllUserTypePayload.sortValue}`
          : ``
      }${
        AllUserTypePayload.sortType === "descending"
          ? `&sort=${AllUserTypePayload.sortValue}`
          : ``
      }${per_page ? `&per_page=${per_page}` : ``}${
        AllUserTypePayload?.filter_dashboard !== "" &&
        AllUserTypePayload.filter_dashboard
          ? `&filter_dashboard=${AllUserTypePayload.filter_dashboard}`
          : ``
      }`
    );
    return res.data;
  }
);
export enum Status {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

interface UserManagementState {
  filterValue:
    | "fourtosevenDayLoginCount"
    | "premium_plan_count"
    | "pro_plan_count"
    | "withinthreeDayLoginCount"
    | "basic_plan_count"
    | "morethansevenDayLoginCount"
    | "weekly_rating_count"
    | "free_plan_count"
    | "basic_plan_count"
    | "session_rating_count"
    | "scc_rating_count"
    | "";
  selectedUser: any[];
  userList: UserTypeList;
  freeUserList: FreeUser[];
  AllUserList: AllUserListType;
  DeleteAPIIdle: Status;
  UserDashboardAPIIdle: Status;
  SwitchWeeklyRatingIdle: Status;
  AllFreeUserAPIIdle: Status;
  UserPostAPIIdle: Status;
  UserListAPIIdle: Status;
  sortType: sortValues;
  sortValue: string;
  userListSortType: sortValues;
  userListSortValue: string;
  UserDetailsAPIIdle: Status;
  getAllUSerListsAPIIdle: Status;
  FreeUserListAPIIdle: Status;
  getAllUSerListsAPIResponse: Array<{ user: UserType[] }>;
  userData: {
    user: UserInfo;
    training_intention: trainingIntention[];
    reps: Reps;
    rate: string;
  };
  pagination: PaginationState;
  rating: UserDashboardAPIResponse;
  perPage: { label: string; value: number };
}

const initialState: UserManagementState = {
  freeUserList: [],
  AllFreeUserAPIIdle: Status.IDLE,
  SwitchWeeklyRatingIdle: Status.IDLE,
  perPage: {
    label: "Show 50",
    value: 50,
  },
  filterValue: "",
  rating: {
    data: {
      free_app: false,
      new_flow: false,
      subscription: {
        basic_plan_count: 0,
        free_plan_count: 0,
        premium_plan_count: 0,
        pro_plan_count: 0,
      },
      login_at: {
        fourtosevenDayLoginCount: 0,
        morethansevenDayLoginCount: 0,
        withinthreeDayLoginCount: 0,
      },
      rating: {
        scc_rating_count: 0,
        session_rating_count: 0,
        weekly_rating_count: 0,
      },
    },
  },
  UserDashboardAPIIdle: Status.SUCCESS,
  selectedUser: [],
  pagination: {
    pageIndex: 1,
    pageSize: 10,
  },
  DeleteAPIIdle: Status.SUCCESS,
  UserPostAPIIdle: Status.SUCCESS,
  UserListAPIIdle: Status.SUCCESS,
  UserDetailsAPIIdle: Status.SUCCESS,
  getAllUSerListsAPIIdle: Status.SUCCESS,
  FreeUserListAPIIdle: Status.SUCCESS,
  sortType: "ascending",
  sortValue: "user",
  userListSortType: "descending",
  userListSortValue: "full_name",
  userList: {
    data: [],
    links: [],
    path: "",
    per_page: 0,
    from: 0,
    to: 0,
    current_page: 0,
    last_page: 0,
    total: 0,
  },
  userData: {
    rate: "",
    training_intention: [],
    reps: {
      move_drills: 0,
      recall_cards: 0,
      swing_drills: 0,
      trust_shots: 0,
    },
    user: {
      group_id: 0,
      _id: "",
      address: "",
      email: "",
      first_name: "",
      last_name: "",
      birthday: "",
      country: "",
      created_at: " ",
      full_name: " ",
      gender: "",
      height: "",
      is_confirm: "",
      postal_code: "",
      profile_picture: "",
      registered_at: "",
      time_zone: "",
      updated_at: "",
      user_type: "",
      userid: "",
      weight: "",
    },
  },
  getAllUSerListsAPIResponse: [],
  AllUserList: {
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    registered_at: "",
    first_name: "",
    userid: 0,
    is_confirm: 0,
    device_type: "",
    fcm_token: "",
    updated_at: "",
    created_at: "",
    last_login_at: "",
    group_id: "",
    group_name: "",
    data: [],
    from: 0,
    last_page: 0,
    links: [],
    per_page: 0,
    to: 0,
    total: 0,
    current_page: 0,
  },
};

const UserManagementSlice = createSlice({
  name: "UserManagement",
  initialState,
  reducers: {
    changeFilterValue: (
      state,
      action: PayloadAction<
        | "fourtosevenDayLoginCount"
        | "withinthreeDayLoginCount"
        | "premium_plan_count"
        | "basic_plan_count"
        | "pro_plan_count"
        | "morethansevenDayLoginCount"
        | "free_plan_count"
        | "weekly_rating_count"
        | "session_rating_count"
        | "scc_rating_count"
        | ""
      >
    ) => {
      state.filterValue = action.payload;
    },
    setPerPage: (state, action) => {
      return {
        ...state,
        perPage: action.payload,
      };
    },
    clearUserList: (state) => {
      return {
        ...state,
        sortType: "ascending",
        userList: {
          data: [],
          links: [],
          path: "",
          per_page: 0,
          from: 0,
          to: 0,
          current_page: 0,
          last_page: 0,
          total: 0,
        },
      };
    },
    UserListSort: (state) => {
      return {
        ...state,
        sortType: state.sortType === "ascending" ? "descending" : "ascending",
      };
    },
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
    checkAllBoxes: (state) => {
      return {
        ...state,
        selectedUser: state.userList.data,
      };
    },
    unCheckAllBoxes: (state) => {
      return {
        ...state,
        selectedUser: [],
      };
    },
    addCheckbox: (state, action) => {
      // Add the checkbox value to the array
      state.selectedUser.push(action.payload);
    },
    removeCheckbox: (state, action) => {
      console.log(action.payload);

      // Remove the checkbox value from the array
      return {
        ...state,
        selectedUser: state.selectedUser.filter(
          (item) => item._id !== action.payload._id
        ),
      };
    },
    changePagination: (state, action: PayloadAction<PaginationState>) => {
      state.pagination = action.payload;
    },
    userListHandleSort: (state, action) => {
      return {
        ...state,
        userListSortType: action.payload.sortType,
        userListSortValue: action.payload.sortValue,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(UserListAPI.pending, (state, action) => {
        return {
          ...state,
          UserListAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        UserListAPI.fulfilled,
        (state, action: PayloadAction<UserTypeListResponse>) => {
          return {
            ...state,
            UserListAPIIdle: Status.SUCCESS,
            userList: {
              ...state.userList,
              data: action.payload.data.data,
              links: action.payload.data.links,
              path: action.payload.data.path,
              per_page: action.payload.data.per_page,
              from: action.payload.data.from,
              to: action.payload.data.to,
              current_page: action.payload.data.current_page,
              last_page: action.payload.data.last_page,
              total: action.payload.data.total,
            },
          };
        }
      )
      .addCase(UserListAPI.rejected, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(DeleteUserAPI.pending, (state, action) => {
        return {
          ...state,
          DeleteAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        DeleteUserAPI.fulfilled,
        (state, action: PayloadAction<DeleteUserAPIResponse>) => {
          return {
            ...state,
            DeleteAPIIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(DeleteUserAPI.rejected, (state, action) => {
        return {
          ...state,
          DeleteAPIIdle: Status.ERROR,
        };
      })
      .addCase(UserPostAPI.pending, (state, action) => {
        return {
          ...state,
          UserPostAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        UserPostAPI.fulfilled,
        (state, action: PayloadAction<UserPostAPIResponse>) => {
          return {
            ...state,
            UserPostAPIIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(UserPostAPI.rejected, (state, action) => {
        return {
          ...state,
          UserPostAPIIdle: Status.ERROR,
        };
      })
      .addCase(UserDetailsAPI.pending, (state, action) => {
        return {
          ...state,
          UserDetailsAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        UserDetailsAPI.fulfilled,
        (state, action: PayloadAction<UserDetailsAPIResponse>) => {
          return {
            ...state,
            UserDetailsAPIIdle: Status.SUCCESS,
            userData: action.payload.data,
          };
        }
      )
      .addCase(UserDetailsAPI.rejected, (state, action) => {
        return {
          ...state,
          UserDetailsAPIIdle: Status.ERROR,
        };
      })
      .addCase(UserDashboardAPI.pending, (state, action) => {
        return {
          ...state,
          UserDetailsAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        UserDashboardAPI.fulfilled,
        (state, action: PayloadAction<UserDashboardAPIResponse>) => {
          return {
            ...state,
            UserDashboardAPIIdle: Status.SUCCESS,
            rating: action.payload,
          };
        }
      )
      .addCase(UserDashboardAPI.rejected, (state, action) => {
        return {
          ...state,
          UserDetailsAPIIdle: Status.ERROR,
        };
      })
      .addCase(AllFreeUserAPI.pending, (state) => {
        state.AllFreeUserAPIIdle = Status.LOADING;
      })
      .addCase(AllFreeUserAPI.fulfilled, (state) => {
        state.AllFreeUserAPIIdle = Status.SUCCESS;
      })
      .addCase(AllFreeUserAPI.rejected, (state) => {
        state.AllFreeUserAPIIdle = Status.ERROR;
      })
      .addCase(getAllUserListsAPI.pending, (state) => {
        state.getAllUSerListsAPIIdle = Status.LOADING;
      })
      .addCase(getAllUserListsAPI.fulfilled, (state, action) => {
        state.getAllUSerListsAPIIdle = Status.SUCCESS;
        state.getAllUSerListsAPIResponse = action.payload.data.users;
      })
      .addCase(getAllUserListsAPI.rejected, (state) => {
        state.getAllUSerListsAPIIdle = Status.ERROR;
      })
      .addCase(FreeUserListAPI.pending, (state) => {
        state.FreeUserListAPIIdle = Status.LOADING;
      })
      .addCase(FreeUserListAPI.fulfilled, (state, action) => {
        state.FreeUserListAPIIdle = Status.SUCCESS;
        state.freeUserList = action.payload.data;
      })
      .addCase(FreeUserListAPI.rejected, (state) => {
        state.getAllUSerListsAPIIdle = Status.ERROR;
      })
      .addCase(SwitchWeeklyRating.pending, (state) => {
        state.SwitchWeeklyRatingIdle = Status.LOADING;
      })
      .addCase(SwitchWeeklyRating.fulfilled, (state, action) => {
        console.log(action.payload);
        const { id, new_value } = action.payload;
        state.SwitchWeeklyRatingIdle = Status.SUCCESS;
        state.userList.data = state.userList.data.map((item) =>
          item._id === id ? { ...item, weekly_rating_switch: new_value } : item
        );
      })
      .addCase(SwitchWeeklyRating.rejected, (state) => {
        state.SwitchWeeklyRatingIdle = Status.ERROR;
      });
  },
});

export const {
  clearUserList,
  addCheckbox,
  changeFilterValue,
  removeCheckbox,
  changePagination,
  setPerPage,
  checkAllBoxes,
  unCheckAllBoxes,
  UserListSort,
  handleSort,
  userListHandleSort,
} = UserManagementSlice.actions;

export default UserManagementSlice.reducer;

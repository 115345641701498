// Import necessary modules for lazy loading and authentication // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the report-related pages
import ReportList from "../../Pages/Report/ReportList";

/**
 * Report Routes
 * Defines the routes for managing and viewing reports in the Report module.
 *
 * Features:
 * 1. Includes routes for viewing the report list and report details.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const reportRoutes = [
  {
    id: "report1", // Unique route identifier
    name: "Report", // Display name for the route
    module: "Report", // Associated module name
    path: "/report", // URL path for the report list
    component: ReportList, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "ReportList", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "report2", // Unique route identifier
    name: "Report Details", // Display name for the route
    module: "Report", // Associated module name
    path: "/reportdetails", // URL path for the report details
    component: ReportList, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "ReportList", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import PaginationComponent from "../../Components/PaginationComponent";
import TableComponent from "../../Components/TableComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import classnames from "classnames";
import {
  UserPostAPI,
  UserListAPI,
  clearUserList,
  DeleteUserAPI,
  UserListSort,
  userListHandleSort,
  Status,
  checkAllBoxes,
  unCheckAllBoxes,
  addCheckbox,
  removeCheckbox,
  changeFilterValue,
  setPerPage,
  AllFreeUserAPI,
  UserDashboardAPI,
  getAllUserListsAPI,
  updateFTUE,
  SwitchWeeklyRating,
} from "../../Redux/features/UserManagementSlice";
import { UserTypeListTableHeader } from "../../utils/TableColumns";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import Sidedrawer from "../../Components/SideDrawer";
import AddUserForm from "../../Containers/UserManagement/AddUserForm";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { useNavigate } from "react-router-dom";
import AssignGroupID from "./AssignGroupID";
import CardSection from "../../Containers/UserManagement/CardSection";
import ExcelIcon from "../../Components/Icons/ExcelIcon";
import { exportToExcel, manageSorting } from "../../utils/helper";
import Switch from "../../Components/Inputs/FormSwitchComponent";
import { notifySuccess } from "../../helper";
import moment from "moment";
import { navbarImages } from "../../assets/navbarasset";
import TextEditor from "../../Components/Inputs/TextEditor";
import NotificationComponent from "../../Components/NotificationSend/NotificationComponent";
import { Badge } from "../Sandbox/Sandbox";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import AssignTag from "./AssignTag";
import { TagListAPI } from "../../Redux/features/TagSlice";
import { useIsMount } from "../../CustomHooks/useIsMount";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import Tooltip from "../../Components/ToolTipComponent/ToolTipComponent";

interface AllUserListType {
  data: AllUserListType[];
  // Add other properties as needed
}

type Props = {};
type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};
const UserListPage = (props: Props) => {
  const perPage = useAppSelector((state) => state.UserManagement.perPage);
  // const [perPage, setPerPage] = useState<{ label: string; value: number }>({
  //   label: "Show 50",
  //   value: 50,
  // });
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    first_name: Yup.string().required("Name is required"),
    last_name: Yup.string().required("Name is required"),
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "", first_name: "", last_name: "" },
  });

  const [userName, setuserName] = useState("");
  const [showUpdateForm, setUpdateForm] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [showAddForm, setAddFormModal] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [id, setid] = useState(0);
  const [userId, setuserId] = useState<string>("");
  const [groupID, setGroupID] = useState<string>("");
  const dispatch = useAppDispatch();
  const userList = useAppSelector(
    (state) => state.UserManagement.userList.data
  );
  const pageNumber = useAppSelector(
    (state) => state.UserManagement.userList.current_page
  );
  const sortType = useAppSelector(
    (state) => state.UserManagement.userListSortType
  );
  const sortValue = useAppSelector(
    (state) => state.UserManagement.userListSortValue
  );

  useEffect(() => {
    dispatch(
      UserListAPI({
        page: 1,
        userType: "user",
        sortType: sortType,
        sortValue: sortValue,
        filter_dashboard: filterValue,
        per_page: perPage.value,
        searchValue: userListValue,
      })
    ).then((response) => {
      dispatch(unCheckAllBoxes());
    });
    return () => {
      dispatch(clearUserList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const getSearchValue = (d: string) => {
    setUserListValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  const [userListValue, setUserListValue] = useState("");
  const [AlluserListValue, setAllUserListValue] = useState("");
  const setPerPageValue = (value: { label: string; value: number }) => {
    dispatch(setPerPage(value));
    dispatch(
      UserListAPI({
        page: 1,
        searchValue: userListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: value.value,
        userType: "user",
        filter_dashboard: filterValue,
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        UserListAPI({
          page: 1,
          userType: "user",
          searchValue: userListValue,
          sortType: sortType,
          sortValue: sortValue,
          filter_dashboard: filterValue,
          per_page: perPage.value,
        })
      ).then((response) => {
        dispatch(unCheckAllBoxes());
      });
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListValue]);

  const handleDeleteClick = (id: number, username: string) => {
    setuserName(username);
    setDeleteForm(true);
    setid(id);
  };
  const handleEditClick = (
    id: number,
    name: string,
    email: string,
    group: string,
    userID?: string
  ) => {
    setUpdateForm(true);
    setValue("email", email);
    setValue("first_name", name);
    setValue("last_name", name);
    setid(id);
    if (userID) {
      setuserId(userID);
    }
    setGroupID(group);
  };

  const handleAdd = (value: any) => {
    dispatch(addCheckbox(value));
  };

  const handleRemove = (value: any) => {
    dispatch(removeCheckbox(value));
  };

  const ConfirmDelete = () => {
    dispatch(DeleteUserAPI(id)).then(() => {
      setDeleteForm(false);
      dispatch(
        UserListAPI({
          per_page: perPage.value,
          page: pageNumber,
          userType: "user",
        })
      );
    });
  };

  const ConfirmUpdate = (data: FormValues) => {
    dispatch(UserPostAPI({ data: data, id: id })).then((res: any) => {
      if (res.payload?.success) {
        setUpdateForm(false);
        dispatch(
          UserListAPI({
            page: pageNumber,
            userType: "user",
            searchValue: userListValue,
            sortType: sortType,
            sortValue: sortValue,
            filter_dashboard: filterValue,
            per_page: perPage.value,
          })
        );
      }
    });
  };

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const handleCloseUpdateModel = () => {
    setUpdateForm(false);
  };
  const formValues = watch();
  const active = useAppSelector(
    (state) => state.UserManagement.userList.current_page
  );

  console.log(active);
  const total = useAppSelector(
    (state) => state.UserManagement.userList.last_page
  );
  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );
  const listLoading = useAppSelector(
    (state) => state.UserManagement.UserListAPIIdle
  );

  const filterValue = useAppSelector(
    (state) => state.UserManagement.filterValue
  );
  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      UserListAPI({
        page: pageNumber,
        userType: "user",
        searchValue: userListValue,
        sortType: sortType,
        sortValue: sortValue,
        filter_dashboard: filterValue,
        per_page: perPage.value,
      })
    ).then((response) => {
      dispatch(unCheckAllBoxes());
    });
  };

  const getSortValue = () => {
    dispatch(UserListSort());
  };

  const navigate = useNavigate();
  const FreeMode = useAppSelector(
    (state) => state.UserManagement.rating.data.new_flow
  );
  const selectedUser = useAppSelector(
    (state) => state.UserManagement.selectedUser
  );
  const FreemoderLoading = useAppSelector(
    (state) => state.UserManagement.AllFreeUserAPIIdle
  );
  const AlluserList = useAppSelector(
    (state) => state.UserManagement.getAllUSerListsAPIIdle
  );
  const users = useAppSelector(
    (state) => state.UserManagement.getAllUSerListsAPIResponse
  );
  console.log(users);

  const formatProgressData = (progressData: UserProgressUserList[]) => {
    const formatted: { [key: string]: string } = {};

    progressData.forEach((item, index) => {
      if (item.unit_name && item.session_name) {
        formatted[`${index + 1}`] = `${item.unit_name}, ${item.session_name}`;
      }
    });

    return formatted;
  };

  const handleExportToExcel = () => {
    if (selectedUser?.length) {
      const formattedData = selectedUser.map((user) => {
        // const progressTrain = formatProgressData(user.user_progress_train);

        return {
          FirstName: user.first_name,
          LastName: user.last_name,
          Email: user.email,
          RegisterDate: moment(user.created_at).format("MM/DD/YYYY"),
          LastLogin: moment(user.last_login_at).format("MM/DD/YYYY"),
          Tag: user.tag_name,
          Program: user.group_name,
          WeeklyRating: user.weekly_rating,
          UnitSession: `${user.unit_name}.${user.session_name}`,
          TrainSession: user.user_progress_train,
          ApplySession: user.user_progress_apply,
          TrustSession: user.user_progress_trust,
          RepCount: user.totalRepCount,
          RepReviews: user.total_rep_reviews,
          Notification: user.allow_notification_permission,
          DailyReminder: user.dailyReminder,
          Subscription: user.subscribed_plan,
          TotalPayments: user.billed_amount,
          RenewalDate: moment(user.renewal_date).format("MM/DD/YYYY"),
          DiscountCode: user.applied_promo_code,
          Device: user.device_type,

          // Program: user.u,
          // WeeklRating:user.user
        };
      });
      console.log(formattedData, users);
      // Collect all unique column names dynamically
      const columnsSet = new Set<string>();
      formattedData.forEach((data) => {
        Object.keys(data).forEach((key) => {
          columnsSet.add(key);
        });
      });
      const columns = Array.from(columnsSet);
      const fileName = `users_Page=${pageNumber}`;
      exportToExcel(formattedData, fileName, columns);
    } else {
      const formattedData = userList.map((user) => {
        // const progressTrain = formatProgressData(user.user_progress_train);

        return {
          FirstName: user.first_name,
          LastName: user.last_name,
          Email: user.email,
          RegisterDate: moment(user.created_at).format("MM/DD/YYYY"),
          LastLogin: moment(user.last_login_at).format("MM/DD/YYYY"),
          Tag: user.tag_name,
          Program: user.group_name,
          WeeklyRating: user.weekly_rating,
          UnitSession: `${user.unit_name}.${user.session_name}`,
          TrainSession: user.user_progress_train,
          ApplySession: user.user_progress_apply,
          TrustSession: user.user_progress_trust,
          RepCount: user.totalRepCount,
          RepReviews: user.total_rep_reviews,
          Notification: user.allow_notification_permission,
          DailyReminder: user.dailyReminder,
          Subscription: user.subscribed_plan,
          TotalPayments: user.billed_amount,
          RenewalDate: moment(user.renewal_date).format("MM/DD/YYYY"),
          DiscountCode: user.applied_promo_code,
          Device: user.device_type,

          // Program: user.u,
          // WeeklRating:user.user
        };
      });
      console.log(formattedData, users);
      // Collect all unique column names dynamically
      const columnsSet = new Set<string>();
      formattedData.forEach((data) => {
        Object.keys(data).forEach((key) => {
          columnsSet.add(key);
        });
      });
      const columns = Array.from(columnsSet);
      const fileName = `users_Page=${pageNumber}`;
      exportToExcel(formattedData, fileName, columns);
    }

    // console.log(formattedData);
  };

  useEffect(() => {
    dispatch(
      getAllUserListsAPI({
        page: pageNumber,
        userType: "user",
        searchValue: AlluserListValue,
        sortType: sortType,
        sortValue: sortValue,
        filter_dashboard: filterValue,
        per_page: perPage.value,
      })
    ).then((response) => {
      dispatch(unCheckAllBoxes());
    });
  }, []);

  const handelNotificationPopup = () => {
    setNotificationOpen((prev) => !prev);
  };
  console.log(selectedUser);
  const handleUpdateStatus = async (i: {
    userid: string;
    email: string;
    full_name: string;
    status: boolean;
  }) => {
    dispatch(
      SwitchWeeklyRating({
        id: i.userid,
        data: {
          email: i.email,
          first_name: i.full_name,
          last_name: i.full_name,
          status: i.status,
        },
      })
    ).then((res) => {
      if (SwitchWeeklyRating.fulfilled.match(res)) {
        if (res.meta.requestStatus === "fulfilled") {
          notifySuccess("User Status Changed");
        }
      }
    });
  };

  const [showSelectTag, setShowSelectTag] = useState(false);
  console.log(showSelectTag);
  useEffect(() => {
    dispatch(TagListAPI({ type: "Users" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tagName, setTagName] = useState("");
  const [tagID, setTagID] = useState("");

  return (
    <>
      <BoxLayout
        cards={<CardSection />}
        headerBgColor="FFFFFF"
        HeaderName="User Dashboard"
        sectionName="User List"
        headerButton={
          <div className="flex gap-3 items-center">
            <Switch
              label="New Flow"
              checked={FreeMode}
              className={classnames("", {
                "opacity-30 cursor-not-allowed":
                  FreemoderLoading === Status.LOADING,
              })}
              disabled={FreemoderLoading === Status.LOADING}
              // register={register}
              name="is_free"
              onChange={(e) => {
                console.log(id);
                console.log("sds");
                dispatch(
                  updateFTUE(FreeMode ? { status: false } : { status: true })
                ).then((res: any) => {
                  if (res.payload.success) {
                    dispatch(UserDashboardAPI());
                    notifySuccess("FTUE Flow Changed");
                  }
                });
              }}
            />
            <button
              className={classnames(
                "border  text-sm font-medium   rounded-xl mx-3 px-[1.88rem] py-2  ",
                {
                  "border-[#BDBDBD] text-[#BDBDBD] opacity-30 cursor-not-allowed":
                    selectedUser.length === 0,
                  "border-[#FC8A2E] text-[#FC8A2E] bg-[#FFF5E5]":
                    selectedUser.length > 0,
                }
              )}
            >
              Email Sents
            </button>
            <button
              onClick={
                selectedUser.length > 0
                  ? handelNotificationPopup
                  : () => {
                      return;
                    }
              }
              className={classnames("pr-5", {
                "opacity-30 cursor-not-allowed": selectedUser.length === 0,
              })}
            >
              <img src={navbarImages.notification} alt="" />
            </button>
            <NotificationComponent
              onclose={handelNotificationPopup}
              onopen={notificationOpen}
            />
          </div>
        }
      >
        <FilterSortPanel
          Function={() => setAddFormModal((prev) => !prev)}
          showAddForm={showAddForm}
          getSearchValue={getSearchValue}
          getSortValue={getSortValue}
          exportButton={
            <>
              <button onClick={handleExportToExcel} className="border rounded">
                <ExcelIcon />
              </button>
              {filterValue !== "" && filterValue ? (
                <button
                  onClick={() => {
                    dispatch(changeFilterValue(""));
                    dispatch(
                      getAllUserListsAPI({
                        per_page: perPage.value,
                        page: 1,
                        userType: "user",
                        searchValue: AlluserListValue,
                        sortType: sortType,
                        sortValue: sortValue,
                      })
                    ).then((response) => {
                      dispatch(unCheckAllBoxes());
                    });
                  }}
                  className="border px-2 rounded py-1 text-xs"
                >
                  Remove Filter
                </button>
              ) : (
                <></>
              )}
            </>
          }
        >
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <Sidedrawer onClose={setAddFormModal}>
              <AddUserForm setAddFormModal={setAddFormModal} />
            </Sidedrawer>
          </div>
        </FilterSortPanel>
        <TableComponent
          TableHeaderData={UserTypeListTableHeader}
          loader={listLoading}
          onClick={manageSorting}
          sortType={sortType}
          sortValue={sortValue}
          handleSortAction={userListHandleSort}
          sortIcon
          checkBox={selectedUser.length === userList.length}
          checkBoxFunction={(e) => {
            if (e.target.checked) {
              dispatch(checkAllBoxes());
            } else {
              dispatch(unCheckAllBoxes());
            }
          }}
        >
          {listLoading === Status.SUCCESS && (
            <tbody>
              {userList.map((i) => (
                <tr className=" border-y border-y-[#EAF0F3] py-1" key={i._id}>
                  <td className="py-1 text-center pr-1">
                    <input
                      checked={selectedUser.includes(i) ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          console.log("World", e.target.checked);
                          handleAdd(i);
                        } else {
                          console.log("hello", e.target.checked);
                          handleRemove(i);
                        }
                      }}
                      type="checkbox"
                      className="accent-orange-600 mr-6"
                    />
                  </td>
                  <td>
                    <div className="flex flex-col gap-1 py-1">
                      <div className="text-xs text-[#212121] text-start font-medium">
                        {i.full_name}
                      </div>
                      <div className=" text-xs text-start text-[#767576]">
                        {i.email}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="py-2 flex flex-col gap-2 justify-start items-start ">
                      <div className="flex gap-2 items-center">
                        {i.tag_name
                          .split(",")
                          .slice(0, 2)
                          .map((j) => (
                            <Badge
                              CTA={i.tag_name === "" ? "No Tag Assigned" : j}
                            />
                          ))}

                        {i.tag_name.split(",").length > 2 ? (
                          <Tooltip text={i.tag_name} className=" text-[12px]">
                            <Badge CTA={"..."} />
                          </Tooltip>
                        ) : (
                          <></>
                        )}

                        <div
                          role="button"
                          onClick={() => {
                            setShowSelectTag(true);
                            setTagName(i.tag_name);
                            setTagID(i.tag_id);
                            setuserId(i._id);
                          }}
                        >
                          <img src="/images/plusButton.svg" alt="" />
                        </div>
                      </div>

                      <div className=" text-xs px-2 text-start text-[#767576]">
                        {i.group_name !== "" ? i.group_name : "Default"}
                      </div>
                    </div>
                  </td>

                  <td className="py-1 flex items-center gap-1 text-xs">
                    {" "}
                    <span className="flex min-w-[2rem] items-center gap-1">
                      {typeof i.weekly_rating === "number" ? (
                        <img
                          src={`/images/cards/${i.weekly_rating}.svg`}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                      <span>{i.weekly_rating}</span>
                    </span>
                    <Switch
                      key={i.userid}
                      label=""
                      checked={i.weekly_rating_switch}
                      className={classnames("", {
                        "opacity-30 cursor-not-allowed":
                          FreemoderLoading === Status.LOADING,
                      })}
                      disabled={FreemoderLoading === Status.LOADING}
                      // register={register}
                      name={String(i.userid)}
                      onChange={async (e) => {
                        console.log(i.userid);
                        handleUpdateStatus({
                          email: i.email,
                          full_name: i.full_name,
                          status: !i.status,
                          userid: i._id,
                        });
                      }}
                    />
                  </td>
                  {/* <td className="py-1 text-xs text-start text-[#767576]">
                    
                  </td> */}
                  <td className="py-1 text-xs text-start text-[#767576]">
                    <span className=" text-xs  font-medium text-[#767576] mx-4 rounded-full px-2 py-1">
                      {!i.session_name && !i.unit_name
                        ? "-"
                        : `${i.unit_name}.${i.session_name}`}
                    </span>
                  </td>
                  <td className="py-1 text-xs text-start text-[#767576]">
                    <div className="flex gap-2 text-[#767576] ">
                      <div className="flex gap-2 flex-col">
                        <span className="font-medium">Train</span>
                        <span>{i.user_progress_train}</span>
                      </div>
                      <div className="flex  gap-2  flex-col">
                        <span className="font-medium">Apply</span>
                        <span>{i.user_progress_apply}</span>
                      </div>
                      <div className="flex   gap-2 flex-col">
                        <span className="font-medium">Trust</span>
                        <span>{i.user_progress_trust}</span>
                      </div>
                    </div>
                  </td>
                  <td className=" text-xs text-start text-[#767576]">
                    <span className="px-3">{i.totalRepCount}</span>
                  </td>
                  <td className=" text-xs text-start text-[#767576]">
                    <div className="py-2 flex flex-col gap-1">
                      <div>
                        Daily Reminder: {i.dailyReminder ? "On" : "Off"}
                      </div>
                      <div>
                        Notifications:
                        {i.allow_notification_permission ? "On" : "Off"}
                      </div>
                    </div>
                  </td>
                  <td className=" text-xs text-start text-[#767576] font-semibold">
                    <div>{moment(i.created_at).format("MM/DD/YYYY")}</div>
                  </td>
                  <td className=" text-xs text-start font-semibold text-[#767576]">
                    <div>{moment(i.last_login_at).format("MM/DD/YYYY")}</div>
                  </td>
                  <td>
                    <div className="grid grid-cols-2 items-center gap-2">
                      <div className="flex flex-col gap-2">
                        <div className="text-[#1F3161] text-xs font-medium">
                          {i.subscribed_plan === "" ? "-" : i.subscribed_plan}
                        </div>
                        <div className="text-[#767576] text-xs">
                          {i.billed_amount === ""
                            ? "-"
                            : `Total $${i.billed_amount}`}
                        </div>
                      </div>
                      <div className="text-[#BDBDBD] text-xs flex flex-col gap-2">
                        <div>
                          {i.renewal_date === "" ? "-" : i.renewal_date}
                        </div>
                        <div>
                          {i.applied_promo_code === ""
                            ? "-"
                            : i.applied_promo_code}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="py-1 text-[#767576] text-xs capitalize">
                    {i.device_type}
                  </td>
                  <td className="py-1 flex gap-3 text-center justify-center">
                    <button
                      onClick={() =>
                        navigate(`/usermanagement/userlist/${i._id}/profile`)
                      }
                    >
                      <img
                        className="h-8"
                        src={"/images/actionbutton.svg"}
                        alt=""
                      />
                    </button>
                  </td>
                  <td className="py-1 hidden gap-3 text-center justify-center">
                    <button
                      onClick={() => handleDeleteClick(i.userid, i.full_name)}
                    >
                      <DeleteIcon />
                    </button>
                    <button
                      onClick={() =>
                        handleEditClick(
                          i.userid,
                          i.full_name,
                          i.email,
                          i?.group_id,
                          i?._id
                        )
                      }
                    >
                      <EditIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {listLoading === Status.LOADING && (
            <TableSkeleton tableColumns={13} />
          )}
        </TableComponent>
        <PaginationComponent
          paginationFunction={pageChange}
          active={active}
          total={total}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
        />
      </BoxLayout>

      <ModalComponent
        confirmAction={ConfirmDelete}
        message={`Are you sure you want to disable ${userName}?`}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
      />

      <CenterPopUpComponent
        isOpen={showSelectTag}
        message="Assign Tag to User"
        onClose={() => setShowSelectTag(false)}
        confirmAction={() => setShowSelectTag(false)}
      >
        <>
          <AssignTag
            closeModal={() => setShowSelectTag(false)}
            tagID={tagID}
            tagName={tagName}
            user_id={userId}
            filterValue={filterValue}
            perPage={perPage}
            sortType={sortType}
            sortValue={sortValue}
          />
        </>
      </CenterPopUpComponent>

      {showUpdateForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[999]">
          <Sidedrawer onClose={handleCloseUpdateModel}>
            <p className="text-lg p-4">Update User</p>
            <form
              className="p-4 flex  flex-col justify-between gap-4"
              onSubmit={handleSubmit(ConfirmUpdate)}
            >
              <div className="h-full flex flex-col gap-4">
                <InputComponent
                  register={register}
                  inputRef="email"
                  label="email"
                  name="email"
                  errorname={errors.email?.message}
                  value={formValues.email}
                />
                <InputComponent
                  register={register}
                  inputRef="first_name"
                  label="name"
                  name="name"
                  errorname={errors.first_name?.message}
                  value={formValues.first_name}
                />
                <InputComponent
                  register={register}
                  inputRef="last_name"
                  label="name"
                  name="name"
                  errorname={errors.last_name?.message}
                  value={formValues.last_name}
                />
              </div>
              <div className="flex h-[150px] gap-6">
                <ButtonComponent
                  buttonType="submit"
                  CTA="Update"
                  varient={"blue"}
                  loading={loading}
                />
                <ButtonComponent
                  buttonType="button"
                  CTA="cancel"
                  onClick={handleCloseUpdateModel}
                />
              </div>
            </form>
            <AssignGroupID
              handleClose={handleCloseUpdateModel}
              userID={userId}
              groupID={groupID}
            />
          </Sidedrawer>
        </div>
      )}
    </>
  );
};

export default UserListPage;

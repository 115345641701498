// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the guide-related pages
import AddFundamentalPage from "../../Pages/Guide/AddFundamentalPage";
import EditFundamentalPage from "../../Pages/Guide/EditFundamentalPage";
import GuidePage from "../../Pages/Guide/GuidePage";

/**
 * Guide Routes
 * Defines the routes for managing guides and fundamentals in the Content Repository module.
 *
 * Features:
 * 1. Includes routes for viewing, adding, and editing fundamentals.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const guideRoutes = [
  {
    id: "guide1", // Unique route identifier
    name: "Fundamentals", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/fundamentals", // URL path for viewing fundamentals
    component: GuidePage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "Guide for drill down", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "guide2", // Unique route identifier
    name: "Add Fundamentals", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/addfundamentals", // URL path for adding fundamentals
    component: AddFundamentalPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "Add Fundamental", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "guide3", // Unique route identifier
    name: "Edit Fundamentals", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/editfundamentals", // URL path for editing fundamentals
    component: EditFundamentalPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "Edit Fundamental", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

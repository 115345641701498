// Import necessary modules and types
import { lazy } from "react"; // For lazy loading components
import { AppRoutesInterface } from ".."; // AppRoutesInterface type definition
import OpenRoutes from "../../auth/OpenRoutes"; // Open route authentication wrapper
import PublicRoutes from "../../auth/PublicRoutes"; // Public route authentication wrapper

// Lazy load the authentication-related pages
import ForgetPasswordPage from "../../Pages/Auth/ForgetPasswordPage";
import LoginPage from "../../Pages/Auth/LoginPage";
import ResetPasswordPage from "../../Pages/Auth/ResetPasswordPage";

/**
 * Authentication Routes
 * Defines the routes for user authentication, including login, forget password, and reset password functionality.
 *
 * Features:
 * 1. Includes routes for public access (login) and open access (password reset).
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumb routes are included for navigation clarity.
 */
export const authRoutes: AppRoutesInterface[] = [
  {
    id: "auth1", // Unique route identifier
    path: "/", // URL path for the login route
    name: "Login", // Display name for the route
    module: "Auth", // Associated module name
    component: LoginPage, // Lazy-loaded component
    auth: PublicRoutes, // Public route authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Login", // Breadcrumb name
        path: "/", // Breadcrumb path for login page
      },
    ],
  },
  {
    id: "auth2", // Unique route identifier
    path: "/forgetpassword", // URL path for the forget password route
    name: "Forget Password", // Display name for the route
    module: "Auth", // Associated module name
    component: ForgetPasswordPage, // Lazy-loaded component
    auth: OpenRoutes, // Open route authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Forget Password", // Breadcrumb name
        path: "#", // Current page does not have a separate path
      },
    ],
  },
  {
    id: "auth3", // Unique route identifier
    path: "/forgetpassword/:hashedID/:email", // URL path for the reset password route
    name: "Reset Password", // Display name for the route
    module: "Auth", // Associated module name
    component: ResetPasswordPage, // Lazy-loaded component
    auth: OpenRoutes, // Open route authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Reset Password", // Breadcrumb name
        path: "/forgetpassword/:hashedID", // Path for breadcrumb navigation
      },
    ],
  },
];

import React, { useEffect } from "react";
import AddObjectTag from "../../Components/AddObjectTag";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { AssignTagAPI } from "../../Redux/features/TagSlice";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { notifySuccess } from "../../helper";
import { UserListAPI } from "../../Redux/features/UserManagementSlice";

type Props = {
  tagID: string;
  tagName: string;
  user_id: string;
  closeModal: () => void;
  sortType: sortValues;
  sortValue: string;
  filterValue:
    | ""
    | "fourtosevenDayLoginCount"
    | "withinthreeDayLoginCount"
    | "premium_plan_count"
    | "basic_plan_count"
    | "pro_plan_count"
    | "morethansevenDayLoginCount"
    | "free_plan_count"
    | "weekly_rating_count"
    | "session_rating_count"
    | "scc_rating_count"
    | undefined;
  perPage: {
    label: string;
    value: number;
  };
};

const AssignTag = ({
  tagID,
  tagName,
  user_id,
  closeModal,
  sortType,
  sortValue,
  filterValue,
  perPage,
}: Props) => {
  const schema = Joi.object({
    tag: Joi.array()
      .items(
        Joi.object({
          value: Joi.string().required(),
          label: Joi.string().required(),
        })
      )
      .optional(), // Field is not required
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const options = useAppSelector((state) => state.Tag.TagList.data.tags);
  useEffect(() => {
    setValue(
      "tag",
      options
        ?.filter((item) => tagID.split(",").includes(item._id))
        ?.map((item) => ({ label: item.name, value: item._id }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const value = watch();

  const dispatch = useAppDispatch();
  console.log(errors);
  const onSubmit = (data: any) => {
    dispatch(
      AssignTagAPI({
        tag_id: data.tag ? data.tag.map((i: any) => i.value).join(",") : "",
        user_id: user_id,
      })
    ).then((res) => {
      if (AssignTagAPI.fulfilled.match(res)) {
        dispatch(
          UserListAPI({
            page: 1,
            userType: "user",
            sortType: sortType,
            sortValue: sortValue,
            filter_dashboard: filterValue,
            per_page: perPage.value,
          })
        );
        closeModal();
        notifySuccess("Tag Assigned Successfully");
      }
    });
  };
  const loading = useAppSelector((state) => state.Tag.AssignTagAPIIdle);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AddObjectTag
        showMenuFull
        control={control}
        errors={errors}
        formValues={value}
        register={register}
      />
      <div className="flex gap-6 px-4">
        <ButtonComponent
          CTA="Cancel"
          onClick={() => closeModal()}
          buttonType="button"
          varient="outline"
        />
        <ButtonComponent
          CTA="Save"
          buttonType="submit"
          varient="blue"
          loading={loading}
        />
      </div>
    </form>
  );
};

export default AssignTag;

// Import necessary modules
import { useState } from "react";
import ButtonComponent from "../../Components/Buttons/ButtonComponent"; // Button component
import InputComponent2 from "../../Components/Inputs/InputComponent2"; // Input component
import { joiResolver } from "@hookform/resolvers/joi"; // Resolver for Yup validation
import { useForm } from "react-hook-form"; // React Hook Form
import { Link, useNavigate } from "react-router-dom"; // Navigation utilities
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks"; // Redux hooks
import { useCookies } from "react-cookie"; // Cookie management
import { LoginAdmin } from "../../Redux/features/UserAuthSlice"; // Login action
import Joi from "joi"; // Joi validation library

// Define the shape of form values
type LoginFormValues = {
  email: string;
  password: string;
};

/**
 * LoginForm
 * The main login form component for handling user authentication.
 *
 * Features:
 * 1. Validates email and password using Yup.
 * 2. Supports "Remember Me" functionality.
 * 3. Handles login for both admin and coach users by checkbox toggle.
 * 4. Sets authentication cookies and redirects on successful login.
 */
const LoginForm = () => {
  const dispatch = useAppDispatch(); // Dispatch Redux actions
  const loading = useAppSelector((state) => state.UserAuth.LoginAdminIdle); // Get loading state
  const navigate = useNavigate(); // Navigation hook
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie] = useCookies(["token", "ic"]); // Cookie management
  const [coachLogin, setCoachLogin] = useState(false); // Coach login toggle
  const [rememberMe, setRememberMe] = useState(false); // Remember Me toggle

  // Validation schema using Yup
  const validationSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "Invalid Email",
        "string.email": "Invalid Email",
        "any.required": "Email is required",
      }),
    password: Joi.string().min(4).required().messages({
      "string.empty": "Invalid Password",
      "string.min": "Password must be at least 4 characters",
      "any.required": "Password is required",
    }),
  });

  // Initialize form with validation
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: { email: "", password: "" },
  });
  console.log(errors);
  const formValues = watch(); // Watch form values

  // Toggle Remember Me
  const handleRememberMe = () => {
    setRememberMe((prev) => !prev);
  };

  // Toggle Coach Login
  const handleCoachLogin = () => {
    setCoachLogin((prev) => !prev);
  };

  // Handle form submission
  const onSubmit = (data: LoginFormValues) => {
    if (data.email !== "" && data.password !== "") {
      dispatch(
        LoginAdmin({
          email: data.email,
          password: data.password,
          user_type: coachLogin ? "coach" : "admin",
        })
      ).then((res) => {
        if (LoginAdmin.fulfilled.match(res) && res.payload.success) {
          // Set cookies based on Remember Me
          setCookie("token", res.payload.token, {
            path: "/",
            ...(rememberMe && { maxAge: 72 * 60 * 60 }), // 72 hours expiry if Remember Me is checked
          });
          setCookie("ic", coachLogin ? "1" : "0", {
            path: "/",
            ...(rememberMe && { maxAge: 72 * 60 * 60 }),
          });

          // Redirect based on user type
          if (coachLogin) {
            navigate("/usermanagement/userlist");
          } else {
            navigate("/coach/swingcheck");
          }

          // Store user information in local storage
          localStorage.setItem("user", res.payload.user.full_name);
        }
      });
    }
  };

  return (
    <div className="md:w-[420px] text-center m-auto h-[calc(100vh-88px)] flex flex-col justify-center items-center">
      <div className="rounded-[12px] w-full py-4">
        {/* Title */}
        <h1 className="text-2xl font-bold text-[#1F3161]">Hi, Welcome back</h1>
        <h3 className="text-base my-2 text-[#9E9E9E] font-normal">
          Login to your account
        </h3>

        {/* Login Form */}
        <form
          className="flex w-[90%] m-auto gap-4 flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Email Input */}
          <InputComponent2
            register={register}
            inputRef="email"
            errorname={errors.email?.message}
            label="Email"
            name="email"
            value={formValues.email}
          />

          {/* Password Input */}
          <InputComponent2
            register={register}
            inputRef="password"
            errorname={errors.password?.message}
            label="Password"
            name="password"
            type="password"
            value={formValues.password}
          />

          {/* Options */}
          <div className="text-start text-[#1F3161] flex flex-col gap-4">
            {/* Coach Login Toggle */}
            <label className="flex select-none cursor-pointer gap-2 text-sm font-medium">
              <input
                className="accent-[#e36600]"
                type="checkbox"
                onChange={handleCoachLogin}
              />
              Login to Coach Portal
            </label>

            {/* Remember Me and Forget Password */}
            <div className="flex justify-between">
              <label className="flex select-none gap-2 cursor-pointer text-sm font-medium">
                <input
                  className="accent-[#e36600]"
                  type="checkbox"
                  onChange={handleRememberMe}
                />
                Remember Me
              </label>
              <Link className="text-sm font-medium" to="/forgetpassword">
                Forget Password?
              </Link>
            </div>
          </div>

          {/* Submit Button */}
          <ButtonComponent loading={loading} CTA="Submit" varient="orange" />
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

// Import necessary modules
import { useEffect } from "react";
import LoginForm from "../../Containers/Auth/LoginForm"; // Login form container

type Props = {};

/**
 * LoginPage
 * The main login page component for the application.
 *
 * Features:
 * 1. Dynamically sets the document title to "FISIO | Login to FISIO".
 * 2. Displays a responsive layout with a login form and an optional background image for larger screens.
 */
const LoginPage = (props: Props) => {
  // Set the document title when the component mounts
  useEffect(() => {
    document.title = "FISIO | Login to FISIO";
  }, []);

  return (
    <div className="box-border h-screen bg-secondary">
      {/* Grid layout for the login page */}
      <div className="grid grid-cols-1 lg:grid-cols-2 w-fullf">
        {/* Left Column: Login Form */}
        <div className="col-span-1">
          {/* Logo */}
          <div className="flex justify-start p-6">
            <img src="/images/logo.svg" className="h-[40px]" alt="FISIO Logo" />
          </div>
          {/* Login Form */}
          <LoginForm />
        </div>

        {/* Right Column: Background Image (hidden on smaller screens) */}
        <div className="col-span-1 hidden lg:flex">
          <img
            className="object-cover w-full"
            src="/images/background.jpg"
            alt="Background"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the pages to optimize initial load time
import AddObjectsPage from "../../Pages/Objects/AddObjectsPage";
import EditObjectPage from "../../Pages/Objects/EditObjectPage";
import ObjectPage from "../../Pages/Objects/ObjectListPage";

/**
 * Object Routes
 * Defines the routes for managing objects in the Content Repository module.
 *
 * Features:
 * 1. Includes routes for viewing, adding, and editing objects.
 * 2. Each route is wrapped with an Admin authentication layer.
 * 3. Lazy-loaded components are used to optimize performance.
 * 4. Breadcrumbs are included for navigation purposes.
 */
export const objectRoutes = [
  {
    id: "object1", // Unique route identifier
    name: "Objects List", // Display name of the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/objects", // URL path for the route
    component: ObjectPage, // Lazy-loaded component
    auth: AdminRoutes, // Authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "/contentrepository/objects", // Path for breadcrumb
      },
      {
        name: "Objects List", // Current page breadcrumb
        path: "#", // Current page does not have a separate path
      },
    ],
  },
  {
    id: "object2", // Unique route identifier
    name: "Add Objects", // Display name of the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/addobjects", // URL path for the route
    component: AddObjectsPage, // Lazy-loaded component
    auth: AdminRoutes, // Authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "/contentrepository/objects", // Path for breadcrumb
      },
      {
        name: "Add Object", // Current page breadcrumb
        path: "#", // Current page does not have a separate path
      },
    ],
  },
  {
    id: "object3", // Unique route identifier
    name: "Edit Objects", // Display name of the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/editobjects", // URL path for the route
    component: EditObjectPage, // Lazy-loaded component
    auth: AdminRoutes, // Authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "/contentrepository/objects", // Path for breadcrumb
      },
      {
        name: "Edit Object", // Current page breadcrumb
        path: "#", // Current page does not have a separate path
      },
    ],
  },
];

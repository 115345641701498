// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the Discount Codes page
import DiscountCodes from "../../Pages/DiscountCodes/DiscountCodesList";

/**
 * Discount Codes Routes
 * Defines the route for managing discount codes in the application.
 *
 * Features:
 * 1. Includes routes for viewing and managing discount codes.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const discountCodes = [
  {
    id: "dc1", // Unique route identifier
    name: "Discount Codes", // Display name for the route
    module: "Discount Codes", // Associated module name
    path: "/discountcodes", // URL path for the Discount Codes page
    component: DiscountCodes, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Discount Codes", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

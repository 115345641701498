import Select, { GroupBase, StylesConfig } from "react-select";
import { DropDownStyles, state } from "../../helper";
import { Controller, Control, FieldValues, Path } from "react-hook-form";
import { useAppDispatch } from "../../Redux/app/hooks";

type Props<TFieldValues extends FieldValues> = {
  asterisk?: boolean;
  placeHolder?: string;
  label?: string;
  options: Array<{ value: string | number; label: string }>;
  setValue?: Function;
  control: Control<TFieldValues>;
  inputRef: Path<TFieldValues>;
  isDisabled?: boolean;
  className?: string;
  selectclassName?: string;
  errorname?: string;
  isMulti?: boolean;
  selectStyle?: StylesConfig<state, false, GroupBase<state>>;
};
const SelectComponent = <TFieldValues extends FieldValues>({
  placeHolder,
  asterisk,
  options,
  isDisabled = false,
  setValue = () => {},
  control,
  inputRef,
  className = "",
  selectclassName = "",
  label = "",
  errorname = "",
  selectStyle = DropDownStyles,
}: Props<TFieldValues>) => {
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-col w-full">
      <Controller
        name={inputRef}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <div className="relative">
              <label
                className={`absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium text-[#b8b8b8] from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 z-10${className}`}
                htmlFor={inputRef}
              >
                {label}
                {asterisk ? (
                  <span className="text-red-800 px-1">*</span>
                ) : (
                  <></>
                )}
              </label>
              <Select
                isMulti={false}
                className={`${selectclassName}`}
                options={options}
                styles={selectStyle}
                placeholder={placeHolder}
                value={options?.filter((i) => i.value === value)}
                onChange={(e) => {
                  onChange(e?.value);
                }}
                isDisabled={isDisabled}
              />
            </div>
          );
        }}
        rules={{ required: true }}
      />
      {errorname !== "" && (
        <span className="text-red-500 text-sm px-2 text-left">{errorname}</span>
      )}
    </div>
  );
};

export default SelectComponent;

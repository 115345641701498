// Import necessary modules and types
import { UseFormRegister, FieldValues, Path } from "react-hook-form";
import OpenEye from "../Icons/OpenEyeIcon";
import CloseEye from "../Icons/CloseEyeIcon";
import { useState } from "react";

type Props<TFieldValues extends FieldValues> = {
  name?: string; // Optional name of the input field
  register: UseFormRegister<TFieldValues>; // React Hook Form's register function for input validation
  errorname?: string | undefined; // Error message to display for the input field
  inputRef: Path<TFieldValues>; // Reference to the field in React Hook Form
  value: string; // Value of the input field
  className?: string; // Additional CSS classes for the wrapper div
  inputClassName?: string; // Additional CSS classes for the input field
  label?: string; // Label for the input field
  placeholder?: string; // Placeholder text for the input field
  disabled?: boolean; // Indicates if the input field is disabled
  astric?: boolean; // Whether to show an asterisk (*) for required fields
  type?: "password" | "text" | "number"; // Type of the input field
  onkeydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Event handler for the keydown event
};

/**
 * InputComponent2
 *
 * A reusable input component that integrates with React Hook Form, supports error handling, and includes password visibility toggle functionality.
 *
 * Props:
 * - `name`: Optional name of the input field.
 * - `register`: React Hook Form's register function for input validation.
 * - `errorname`: Error message to display for the input field.
 * - `inputRef`: Reference to the field in React Hook Form.
 * - `value`: Value of the input field.
 * - `className`: Additional CSS classes for the wrapper div.
 * - `inputClassName`: Additional CSS classes for the input field.
 * - `label`: Label for the input field.
 * - `placeholder`: Placeholder text for the input field.
 * - `disabled`: Indicates if the input field is disabled (default: false).
 * - `astric`: Whether to show an asterisk (*) for required fields (default: true).
 * - `type`: Type of the input field (`password`, `text`, or `number`) (default: "text").
 * - `onkeydown`: Event handler for the keydown event.
 */
const InputComponent2 = <TFieldValues extends FieldValues>({
  label = "", // Default label is an empty string
  register,
  errorname,
  inputRef,
  className = "", // Default className is an empty string
  inputClassName = "", // Default inputClassName is an empty string
  placeholder = "", // Default placeholder is an empty string
  disabled = false, // Default disabled state is false
  astric = true, // Default astric state is true
  type = "text", // Default input type is "text"
  onkeydown, // Optional keydown event handler
}: Props<TFieldValues>) => {
  const [showPassword, setshowPassword] = useState(true); // State for toggling password visibility

  return (
    <div className={`${className} bg-[#fafafa]`}>
      <div
        className={`h-[50px] w-full rounded-[12px] justify-end flex relative border border-[#B8B8B8]`}
      >
        {/* Input Field */}
        <input
          onKeyDown={onkeydown} // Pass the event handler for keydown
          {...register(inputRef)} // Register the input field with React Hook Form
          type={
            type === "password"
              ? showPassword && type === "password"
                ? "password"
                : "text"
              : type
          } // Toggle password visibility
          disabled={disabled} // Set disabled state
          className={`w-full h-full peer bg-[#fafafa] rounded-[12px] outline-none px-3 pt-3 animate-none ${inputClassName}`} // Input field styles
          placeholder={placeholder} // Input field placeholder
        />

        {/* Label */}
        <label
          className="absolute left-1 flex items-center gap-1 top-[3px] bg-gradient-to-b text-xs font-medium text-[#b8b8b8] px-2"
          htmlFor={label}
        >
          {label}
          {astric ? <span className="text-red-600">*</span> : null}{" "}
          {/* Show asterisk if required */}
        </label>

        {/* Password Toggle Button */}
        {type === "password" && (
          <div className="absolute p-2 rounded-r-[12px] bg-[#fafafa]">
            {showPassword ? (
              <button type="button" onClick={() => setshowPassword(false)}>
                <OpenEye /> {/* Show OpenEye icon when password is hidden */}
              </button>
            ) : (
              <button type="button" onClick={() => setshowPassword(true)}>
                <CloseEye /> {/* Show CloseEye icon when password is visible */}
              </button>
            )}
          </div>
        )}
      </div>

      {/* Error Message */}
      {errorname && (
        <span className="text-red-500 text-sm px-2 text-left">{errorname}</span>
      )}
    </div>
  );
};

export default InputComponent2;

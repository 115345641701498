import React, { useEffect, useState } from "react";
import ArrowIcon from "./Icons/ArrowIcon";
import { useAppSelector } from "../Redux/app/hooks";
import SelectInput, { OptionType } from "./SelectInput/SelectInput";
import { customStyles } from "../helper";
import { ActionMeta, SingleValue } from "react-select";

type Props = {
  active: number;
  paginationFunction?: Function;
  total: number;
  setPerPageValue?: Function;
  perPageValue?: { label: string; value: number };
};

const PaginationComponent = ({
  active,
  total,
  paginationFunction = () => {},
  setPerPageValue,
  perPageValue,
}: Props) => {
  const [pagination, setPagination] = useState<number[]>([]);
  useEffect(() => {
    let array = [];
    for (let i = 1; i <= total; i++) {
      array.push(i);
    }
    setPagination(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  const handleChange: (
    selectedOption: SingleValue<OptionType<number>>,
    _actionMeta: ActionMeta<OptionType<number>>
  ) => void = (selectedOption, _actionMeta) => {
    if (Number(selectedOption?.value) && setPerPageValue) {
      setPerPageValue(selectedOption);
    }
  };
  return (
    <div className="flex text-sm justify-between items-center">
      <div className="flex items-center justify-between gap-3 z-40  ">
        <div className="flex text-sm bg-secondary-light text-primaryFont-light mx-4 px-2 my-1 py-2 rounded-md items-center">
          <button
            disabled={active === 1}
            onClick={() => paginationFunction(active - 1)}
          >
            <ArrowIcon direction="" />
          </button>
          <div className="flex items-center gap-4 mx-4">
            {total <= 5 &&
              pagination.map((item, index) => (
                <button
                  key={index}
                  onClick={() => paginationFunction(item)}
                  className={`${
                    active === item
                      ? "bg-bluegradient text-white w-6 h-6 flex justify-center items-center rounded-full p-2"
                      : ""
                  }`}
                >
                  {index + 1}
                </button>
              ))}{" "}
            {total > 5 && active >= 2 ? (
              <>
                <span
                  className="cursor-pointer"
                  onClick={() => paginationFunction(1)}
                >
                  {1}
                </span>
                <span className="">...</span>
              </>
            ) : null}
            {total > 5 &&
              active >= 3 &&
              pagination.slice(active - 2, active + 1).map((item, index) => (
                <>
                  <button
                    disabled={active === item}
                    onClick={() => paginationFunction(item)}
                    key={index}
                    className={`outline-none ${
                      active === item
                        ? "bg-bluegradient cursor-pointer text-white w-6 h-6 flex justify-center items-center rounded-full p-2"
                        : ""
                    }`}
                  >
                    {item}
                  </button>
                </>
              ))}
            {total > 5 &&
              active < 3 &&
              pagination.slice(active - 1, active + 2).map((item, index) => (
                <span
                  key={index}
                  onClick={() => paginationFunction(item)}
                  className={`cursor-pointer ${
                    active === item
                      ? "cursor-pointer bg-bluegradient text-white w-6 h-6 flex justify-center items-center rounded-full p-2"
                      : ""
                  }`}
                >
                  {item}
                </span>
              ))}
            {total > 5 && active < total - 1 && (
              <>
                <span className="">...</span>
                <span
                  className="cursor-pointer"
                  onClick={() => paginationFunction(total)}
                >
                  {total}
                </span>
              </>
            )}
          </div>
          <button
            disabled={active === total}
            onClick={() => paginationFunction(active + 1)}
          >
            <ArrowIcon direction="rotate-180" />
          </button>
        </div>
        {/* <span className="flex items-center">
          10 Rows
          <div className="px-3">
            <ArrowIcon direction="-rotate-90" />
          </div>
        </span> */}
      </div>
      {perPageValue && (
        <SelectInput
          customStyles={customStyles}
          onChange={(newValue, actionMeta) =>
            handleChange(newValue, actionMeta)
          }
          options={[
            { value: 10, label: "Show 10" },
            { value: 20, label: "Show 20" },
            { value: 30, label: "Show 30" },
            { value: 40, label: "Show 40" },
            { value: 50, label: "Show 50" },
            { value: 100, label: "Show 100" },
            { value: 1000, label: "Show All" },
          ]}
          selectedOption={perPageValue}
        />
      )}
    </div>
  );
};

export default PaginationComponent;

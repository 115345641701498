// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the program-related pages
import AddGroupPage from "../../Pages/Group/AddGroupPage";
import EditGroupPage from "../../Pages/Group/EditGroupPage";
import GroupList from "../../Pages/Group/GroupList";

/**
 * Program Routes
 * Defines the routes for managing programs in the Content Repository module.
 *
 * Features:
 * 1. Includes routes for viewing, adding, and editing programs.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const programRoutes = [
  {
    id: "program1", // Unique route identifier
    name: "Program", // Display name for the route
    module: "Program", // Associated module name
    path: "/contentrepository/group", // URL path for the program list
    component: GroupList, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Program", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "program2", // Unique route identifier
    name: "Add Program", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/addgroup", // URL path for adding a program
    component: AddGroupPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Program", // Parent breadcrumb
        path: "/contentrepository/group", // Path for breadcrumb
      },
      {
        name: "Add Program", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "program3", // Unique route identifier
    name: "Edit Program", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/editgroup", // URL path for editing a program
    component: EditGroupPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Program", // Parent breadcrumb
        path: "/contentrepository/group", // Path for breadcrumb
      },
      {
        name: "Edit Program", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

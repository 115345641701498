// Import required modules
import axios from "axios";
import { Cookies } from "react-cookie"; // For managing cookies
import { notifyError } from "../helper"; // Custom notification helper

/**
 * Axios Client
 * A customized Axios instance with pre-configured interceptors for handling
 * requests and responses.
 *
 * Features:
 * 1. Handles API responses, including error scenarios, with user-friendly notifications.
 * 2. Automatically attaches Authorization tokens for secured routes.
 * 3. Redirects to the login page on token expiry or unauthorized access.
 */

// Create an Axios instance with a base URL and default headers
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Base URL from environment variables
  headers: {
    Accept: "application/json", // Set the default Accept header
  },
});

/**
 * Response Interceptor
 * Handles various HTTP response status codes and provides appropriate actions.
 */
axiosClient.interceptors.response.use(
  function (response) {
    // Return the response as-is if it's successful
    return response;
  },
  async function (error) {
    console.log(error); // Log the error for debugging
    let res = error.response;

    // Handle specific HTTP status codes
    switch (res.status) {
      case 401: // Unauthorized
        const cookies = new Cookies();
        cookies.remove("token"); // Remove token on unauthorized access
        cookies.remove("ic");
        window.location.href = "/"; // Redirect to the login page
        break;
      case 400: // Bad Request
        notifyError(res.data.message); // Show error message
        break;
      case 403: // Forbidden
        notifyError("Access Denied");
        break;
      case 404: // Not Found
        notifyError("Resource not found");
        break;
      case 408: // Request Timeout
        notifyError("Request Timeout");
        break;
      case 409: // Conflict
        notifyError("Conflict: " + res.data.message);
        break;
      case 422: // Unprocessable Entity
        notifyError("Unprocessable Entity: " + res.data.message);
        break;
      case 429: // Too Many Requests
        notifyError("Too many requests. Please try again later.");
        break;
      case 500: // Internal Server Error
        notifyError("Internal Server Error");
        break;
      case 502: // Bad Gateway
        notifyError("Bad Gateway");
        break;
      case 503: // Service Unavailable
        notifyError("Service Unavailable");
        break;
      case 504: // Gateway Timeout
        notifyError("Gateway Timeout");
        break;
      default: // Other unexpected errors
        notifyError("An unexpected error occurred");
        break;
    }
    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

/**
 * Request Interceptor
 * Adds Authorization headers for requests if a token exists in cookies.
 */
axiosClient.interceptors.request.use(
  function (request) {
    // Skip Authorization for login requests
    if (request.url !== "auth/login") {
      const cookies = new Cookies();
      const token = cookies.get("token"); // Retrieve the token from cookies
      request.headers.Authorization = `Bearer ${token}`; // Add token to Authorization header
    }
    return request;
  },
  function (error) {
    console.log(error); // Log the error for debugging
    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

export default axiosClient;

import { lazy, ReactNode, LazyExoticComponent } from "react"; // Import lazy and Suspense
import AdminRoutes from "../auth/AdminRoutes"; // Admin authentication wrapper

// Import other route groups as usual
import { objectRoutes } from "../routes/contentrepository/objectRoutes";
import { sessionRoutes } from "../routes/contentrepository/sessionRoutes";
import { unitRoutes } from "../routes/contentrepository/unitRoutes";
import { tagRoutes } from "../routes/contentrepository/tagRoutes";
import { recallCardRoutes } from "../routes/contentrepository/recallCardsRoutes";
import { authRoutes } from "../routes/auth/authRoutes";
import { usermanagementRoutes } from "../routes/usermanagement/usermanagementRoutes";
import { onBoardingRoutes } from "../routes/onBoarding/onBoardingRoutes";
import { coachRoutes } from "../routes/coach/coachRoutes";
import { programRoutes } from "../routes/contentrepository/programRoutes";
import { reportRoutes } from "./report/reportRoutes";
import { userDashboardRoutes } from "./usermanagement/userDashboardRoutes";
import { guideRoutes } from "./contentrepository/guideRoutes";
import { affiliateRoutes } from "./affiliate/affiliateRoutes";
import { appControlRoutes } from "./appcontrol/appControlRoutes";
import { discountCodes } from "./discountcodes/discountCodesRoutes";
import { faqRoutes } from "./Faq/faq";
import { freeUserRoutes } from "./freeUser/freeUser";
import { scaRoutes } from "./sca/sca";
// Define lazy-loaded components
import TrophiesList from "../Pages/Trophies/TrophiesList";
import TrustPage from "../Pages/Trust/TrustPage";

// Define the structure for a single route
export interface AppRoutesInterface {
  id: string; // Unique identifier for the route
  name: string; // Display name of the route
  path: string; // URL path for the route
  module: string; // Associated module name
  component:
    | ((props: {}) => JSX.Element) // Regular component
    | LazyExoticComponent<(props: {}) => JSX.Element>; // Component to render for the route
  auth: (props: { children: ReactNode }) => JSX.Element; // Authentication wrapper for the route
  breadCrumbRoutes?: Array<{
    name: string; // Name for breadcrumb navigation
    path: string; // Path for breadcrumb navigation
  }>;
}

// Consolidate all routes into a single array
export const AppRoutes: AppRoutesInterface[] = [
  // Spread in routes from individual modules
  ...authRoutes,
  ...usermanagementRoutes,
  ...onBoardingRoutes,
  ...objectRoutes,
  ...sessionRoutes,
  ...unitRoutes,
  ...tagRoutes,
  ...recallCardRoutes,
  ...coachRoutes,
  ...programRoutes,
  ...reportRoutes,
  ...userDashboardRoutes,
  ...guideRoutes,
  ...affiliateRoutes,
  ...appControlRoutes,
  ...discountCodes,
  ...faqRoutes,
  ...freeUserRoutes,
  ...scaRoutes,

  // Additional standalone routes
  {
    id: "26", // Unique route ID
    name: "Manage Trophies", // Route display name
    module: "Locker", // Associated module
    path: "/contentrepository/trophies", // URL path
    component: TrophiesList, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Trophies", // Breadcrumb name
        path: "#", // Breadcrumb path (stub in this case)
      },
    ],
  },
  {
    id: "28", // Unique route ID
    name: "Trust", // Route display name
    module: "Locker", // Associated module
    path: "/trust", // URL path
    component: TrustPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Trust", // Breadcrumb name
        path: "#", // Breadcrumb path (stub in this case)
      },
    ],
  },
];

import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import {
  UserPostAPI,
  UserListAPI,
} from "../../Redux/features/UserManagementSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { toast } from "react-toastify";
import { notifyError } from "../../helper";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  confirmPassword: string;
  type: string;
};

const AddAdminForm = ({ setAddFormModal }: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    first_name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters"),
    last_name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters"),
    type: Yup.string(),
  });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector(
    (state) => state.UserManagement.userList.current_page
  );
  const onSubmit = async (data: FormValues) => {
    dispatch(
      UserPostAPI({
        data: {
          email: data.email,
          password: data.password,
          first_name: data.first_name,
          last_name: data.last_name,
          user_type: data.type,
        },
      })
    ).then((res) => {
      console.log(res);
      setAddFormModal(false);
      dispatch(UserListAPI({ page: pageNumber, userType: "admin" }));
    });
  };

  const formValues = watch();

  const options = [
    {
      value: "admin",
      label: "admin",
    },
    { value: "coach", label: "coach" },
  ];
  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="p-4 flex flex-col h-full ">
      <h2 className="text-xl font-bold mb-4">Add Admin</h2>
      <form onSubmit={handleSubmit(onSubmit)} className=" mb-4">
        <div className="flex flex-col justify-between h-screen">
          <div className="flex flex-col  gap-3 justify-between">
            <InputComponent
              // errorname={errors.email?.message}
              inputRef="email"
              label="Email"
              name="email"
              register={register}
              value={formValues.email}
            />
            <InputComponent
              // errorname={errors.password?.message}
              inputRef="password"
              label="Password"
              name="password"
              register={register}
              value={formValues.password}
            />
            <InputComponent
              // errorname={errors.confirmPassword?.message}
              inputRef="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              register={register}
              value={formValues.confirmPassword}
            />
            <InputComponent
              // errorname={errors.name?.message}
              inputRef="first_name"
              label="First Name"
              name="first_name"
              register={register}
              value={formValues.name}
            />
            <InputComponent
              // errorname={errors.name?.message}
              inputRef="last_name"
              label="Last Name"
              name="last_name"
              register={register}
              value={formValues.name}
            />
            <SelectComponent<FormValues>
              label="Role"
              inputRef="type"
              control={control}
              options={options}
            />
            <p className="text-xs text-center">
              After creating user here, user have to verify his email by
              verification link which is sent on provided email to activate
              account
            </p>
          </div>

          <div className="flex h-[150px] gap-4">
            <ButtonComponent
              CTA="Save"
              loading={loading}
              buttonType="submit"
              varient="blue"
            />
            <ButtonComponent
              CTA="Cancel"
              onClick={() => setAddFormModal(false)}
              buttonType="button"
              varient="outline"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAdminForm;

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the tag list page
import TagListPage from "../../Pages/Tags/TagPage";

/**
 * Tag Routes
 * Defines the routes for managing tags in the Tags Repository module.
 *
 * Features:
 * 1. Includes a route for viewing the tags list.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const tagRoutes = [
  {
    id: "21", // Unique route identifier
    name: "Tags List", // Display name for the route
    module: "Tags", // Associated module name
    path: "/TagsRepository/Tags", // URL path for the tags list route
    component: TagListPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Tags", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "Tags List", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

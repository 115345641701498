// Import necessary modules and types
import { useEffect, useState } from "react";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {
  CTA: string; // Call-to-Action text for the button
  onClick?: Function; // Callback function to handle button click
  varient?: string; // Button style variant
  loading?: Status; // Loading status for the button
  className?: string; // Additional CSS classes for customization
  buttonType?: "button" | "submit" | "reset"; // Button type attribute
  disabled?: boolean; // Disable state of the button
};

/**
 * ButtonComponent
 *
 * A reusable button component with dynamic styles, click handling, and a loading state.
 *
 * Props:
 * - CTA: string - The text displayed on the button.
 * - onClick?: Function - A callback function invoked on button click (default: no-op).
 * - varient?: string - Determines the button's style (e.g., blue, orange, white).
 * - loading?: Status - Shows a loader when the button is in a loading state.
 * - className?: string - Additional CSS classes for customization.
 * - buttonType?: "button" | "submit" | "reset" - The type attribute for the button (default: "submit").
 * - disabled?: boolean - Indicates whether the button is disabled (default: false).
 */
const ButtonComponent = ({
  CTA,
  disabled = false,
  onClick = () => {}, // Default no-op function
  varient = "blue",
  loading = Status.SUCCESS, // Default status is SUCCESS
  buttonType = "submit",
  className,
}: Props) => {
  const [buttonStyle, setButtonStyle] = useState("");

  useEffect(() => {
    // Set the button style based on the varient prop
    switch (varient) {
      case "blue":
        setButtonStyle("bg-bluegradient text-white");
        break;
      case "orange":
        setButtonStyle(
          "bg-gradient-to-l text-white from-gradientPairs-orangeStart from-[-4.22%] to-gradientPairs-orangeEnd to-[120.23%]"
        );
        break;
      case "white":
        setButtonStyle("bg-gradient-to-l text-[#D84315] border");
        break;
      case "outline":
        setButtonStyle("bg-white border h-[44px] text-black");
        break;
      case "simple":
        setButtonStyle("text-red-700");
        break;
      default:
        setButtonStyle("");
    }
  }, [varient]);

  /**
   * Handle ripple effect animation on button click.
   * @param e React.MouseEvent<HTMLButtonElement>
   */
  const handleRipple = (e: React.MouseEvent<HTMLButtonElement>) => {
    const ripple = document.createElement("span");
    ripple.className = "ripple";
    const rect = e.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    e.currentTarget.appendChild(ripple);
    setTimeout(() => {
      ripple.remove();
    }, 400);
  };

  return (
    <>
      {buttonStyle !== "" && (
        <div className={`w-full h-[44px] relative rounded-[4px] ${className}`}>
          <button
            type={buttonType}
            onClick={(e) => {
              handleRipple(e);
              onClick();
            }}
            disabled={loading === Status.LOADING || disabled}
            className={`h-[44px] w-full rounded-[4px] ${buttonStyle} transition-transform`}
          >
            {loading !== Status.LOADING ? (
              CTA
            ) : (
              <img className="m-auto" src="/images/loader.gif" alt="loader" />
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default ButtonComponent;

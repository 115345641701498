import { UseFormRegister, FieldValues, Path } from "react-hook-form";
import OpenEye from "../Icons/OpenEyeIcon";
import CloseEye from "../Icons/CloseEyeIcon";
import { useState } from "react";

type Props<TFieldValues extends FieldValues> = {
  name?: string;
  register: UseFormRegister<TFieldValues>;
  errorname?: string | undefined;
  inputRef: Path<TFieldValues>;
  value: string;
  className?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  astric?: boolean;
  type?: "password" | "text" | "number";
  onkeydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const InputComponent = <TFieldValues extends FieldValues>({
  label = "",
  register,
  errorname,
  inputRef,
  className = "",
  inputClassName = "",
  placeholder = "",
  disabled = false,
  astric = true,
  type = "text",
  onkeydown,
}: Props<TFieldValues>) => {
  const [showPassword, setshowPassword] = useState(true);
  return (
    <div className={`${className} bg-[#fafafa]`}>
      <div
        className={`h-[50px] w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8]`}
      >
        <input
          onKeyDown={onkeydown} // Pass the event handler directly
          {...register(inputRef)}
          type={
            type === "password"
              ? showPassword && type === "password"
                ? "password"
                : "text"
              : type
          }
          disabled={disabled}
          className={`w-full h-full font-medium peer bg-[#fafafa] rounded-[12px] outline-none px-3 pt-2 animate-none ${inputClassName}`}
          placeholder={placeholder}
        />

        <label
          className="absolute left-4 flex items-center gap-1 -top-2 bg-gradient-to-b text-xs font-medium text-[#b8b8b8]  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
          htmlFor={"userName"}
        >
          {label}
          {astric ? <span className="text-red-600  ">*</span> : <></>}
        </label>
        {type === "password" && (
          <div className="abosulte p-4 rounded-r-[12px] bg-[#fafafa]">
            {showPassword ? (
              <button type="button" onClick={() => setshowPassword(false)}>
                <OpenEye />
              </button>
            ) : (
              <button type="button" onClick={() => setshowPassword(true)}>
                <CloseEye />
              </button>
            )}
          </div>
        )}
      </div>
      {errorname && (
        <span className="text-red-500 text-sm px-2 text-left">{errorname}</span>
      )}
    </div>
  );
};

export default InputComponent;

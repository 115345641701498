import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useForm } from "react-hook-form";
import InputComponent from "../../Components/Inputs/InputComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { AddTagAPI, TagListAPI } from "../../Redux/features/TagSlice";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import SelectComponent from "../../Components/Inputs/SelectComponent";

type Props = {
  handleCloseUpdateModel: Function;
  tagName: string;
  id: string;
  tagType: string;
};
type FormValues = {
  name: string;
  type: string;
};
const UpdateTagForm = ({
  handleCloseUpdateModel,
  tagType,
  tagName,
  id,
}: Props) => {
  const dispatch = useAppDispatch();
  const validationSchema = Joi.object({
    name: Joi.string().min(2).required().messages({
      "string.min": "TagName must be at least 2 characters",
      "any.required": "TagName is required",
    }),
    type: Joi.string().min(2).required().messages({
      "string.min": "TagName must be at least 2 characters",
      "any.required": "TagName is required",
    }),
  });
  const sortType = useAppSelector((state) => state.Tag.sortType);
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: tagName,
      type: tagType,
    },
  });
  const formValues = watch();
  const ConfirmUpdate = (data: FormValues) => {
    dispatch(AddTagAPI({ id: id, name: data.name, type: data.type })).then(
      () => {
        handleCloseUpdateModel();
        dispatch(TagListAPI({ sortType: sortType }));
      }
    );
  };

  const loading = useAppSelector((state) => state.Tag.AddTagAPIIdle);

  return (
    <>
      <p className="text-lg p-4">Update Tag</p>
      <form
        className="p-4 flex  h-full flex-col justify-between gap-4"
        onSubmit={handleSubmit(ConfirmUpdate)}
      >
        <div className="h-full flex flex-col gap-4">
          <InputComponent
            register={register}
            inputRef="name"
            label="Tag Name"
            name="email"
            // errorname={errors.name?.message}
            value={formValues.name}
          />
          <SelectComponent
            errorname={errors?.type?.message}
            inputRef="type"
            label="Tag Type"
            control={control}
            options={[
              {
                value: "Users ",
                label: "Users",
              },
              {
                value: "Content",
                label: "Content",
              },
            ]}
            asterisk
          />
        </div>
        <div className="flex h-[150px] gap-6">
          <ButtonComponent
            buttonType="submit"
            CTA="Update"
            varient={"blue"}
            loading={loading}
          />
          <ButtonComponent
            buttonType="button"
            varient="outline"
            CTA="Cancel"
            onClick={handleCloseUpdateModel}
          />
        </div>
      </form>
    </>
  );
};

export default UpdateTagForm;

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the affiliate-related pages
import AffiliateDetails from "../../Pages/Affiliate/AffiliateDetails";
import AffiliateList from "../../Pages/Affiliate/AffiliateList";

/**
 * Affiliate Routes
 * Defines the routes for managing affiliates in the Affiliate module.
 *
 * Features:
 * 1. Includes routes for viewing the affiliate list and affiliate details.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const affiliateRoutes = [
  {
    id: "affiliate1", // Unique route identifier
    name: "Affiliate List", // Display name for the route
    module: "Affiliate", // Associated module name
    path: "/affiliate", // URL path for the affiliate list
    component: AffiliateList, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Affiliate", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "Affiliate List", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "affiliate2", // Unique route identifier
    name: "Affiliate Details", // Display name for the route
    module: "Affiliate Details", // Associated module name
    path: "/affiliate/details", // URL path for affiliate details
    component: AffiliateDetails, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Affiliate List", // Parent breadcrumb
        path: "/affiliate", // Path for breadcrumb
      },
      {
        name: "Affiliate Details", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper
import CoachRoutes from "../../auth/CoachRoutes"; // Coach authentication wrapper

// Lazy load the coach-related pages
import CoachDashboard from "../../Pages/Coach/CoachDashboard";
import CoachReviewPage from "../../Pages/Coach/CoachListPage";
import TicketReviewList from "../../Pages/Coach/TicketReviewList";

/**
 * Coach Routes
 * Defines the routes for coach reviews, tickets, and dashboards.
 *
 * Features:
 * 1. Includes routes for managing coach dashboards and ticket reviews.
 * 2. Supports separate authentication for admin and coach users.
 * 3. Uses lazy-loaded components to optimize initial load performance.
 * 4. Breadcrumbs are included for navigation clarity.
 */
export const coachRoutes = [
  {
    id: "coach1", // Unique route identifier
    name: "Tickets", // Display name for the route
    module: "Tickets", // Associated module name
    path: "/coachreview/tickets", // URL path for the coach dashboard
    component: CoachDashboard, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Coach Review", // Parent breadcrumb
        path: "/coachreview/tickets", // Path for breadcrumb
      },
      {
        name: "Coach Review List", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "coach2", // Unique route identifier
    name: "Coaches", // Display name for the route
    module: "Coaches", // Associated module name
    path: "/coachreview/coaches", // URL path for the coach review list
    component: CoachReviewPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Coach Review", // Parent breadcrumb
        path: "/coachreview/tickets", // Path for breadcrumb
      },
      {
        name: "Coaches List", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "coach3", // Unique route identifier
    name: "Tickets", // Display name for the route
    module: "Tickets", // Associated module name
    path: "/coachreview/tickets/:ticketid", // URL path for ticket review details
    component: TicketReviewList, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Coach Review", // Parent breadcrumb
        path: "/coachreview/tickets", // Path for breadcrumb
      },
      {
        name: "Coach Review Detail", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "coach5", // Unique route identifier
    name: "Coach", // Display name for the route
    module: "Coach Review", // Associated module name
    path: "/coachreview/list", // URL path for the coach list
    component: CoachDashboard, // Lazy-loaded component
    auth: CoachRoutes, // Coach authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Coach Review", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "coach6", // Unique route identifier
    name: "Coach", // Display name for the route
    module: "Review Details", // Associated module name
    path: "/coachreview/list/details", // URL path for coach review details
    component: TicketReviewList, // Lazy-loaded component
    auth: CoachRoutes, // Coach authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Coach Review", // Parent breadcrumb
        path: "/coachreview/list", // Path for breadcrumb
      },
      {
        name: "Coach Review Detail", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the session-related pages
import AddSessionPage from "../../Pages/Sessions/AddSessionPage";
import EditSessionPage from "../../Pages/Sessions/EditSessionPage";
import SessionListPage from "../../Pages/Sessions/SessionListPage";

/**
 * Session Routes
 * Defines the routes for managing sessions in the Content Repository module.
 *
 * Features:
 * 1. Includes routes for viewing, adding, and editing sessions.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const sessionRoutes = [
  {
    id: "session1", // Unique route identifier
    name: "Session List", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/session", // URL path for the session list route
    component: SessionListPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "/contentrepository/session", // Path for breadcrumb
      },
      {
        name: "Session List", // Current page breadcrumb
        path: "#", // Current page does not have a separate path
      },
    ],
  },
  {
    id: "session2", // Unique route identifier
    name: "Add Session", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/addsession", // URL path for the add session route
    component: AddSessionPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "/contentrepository/session", // Path for breadcrumb
      },
      {
        name: "Add Session", // Current page breadcrumb
        path: "#", // Current page does not have a separate path
      },
    ],
  },
  {
    id: "session3", // Unique route identifier
    name: "Edit Session", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/editsession", // URL path for the edit session route
    component: EditSessionPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Content Repository", // Parent breadcrumb
        path: "/contentrepository/session", // Path for breadcrumb
      },
      {
        name: "Edit Session", // Current page breadcrumb
        path: "#", // Current page does not have a separate path
      },
    ],
  },
];

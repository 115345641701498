// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the user dashboard-related pages
import ActivityPage from "../../Pages/Users/UserDetails/Activity/ActivityPage";
import FeedbackPage from "../../Pages/Users/UserDetails/Feedback/FeedbackPage";
import Notification from "../../Pages/Users/UserDetails/Notification/Notification";
import ProfilePage from "../../Pages/Users/UserDetails/Profile/ProfilePage";
import RatingPage from "../../Pages/Users/UserDetails/Rating/RatingPage";
import Subscription from "../../Pages/Users/UserDetails/Subscription/Subscription";
import Support from "../../Pages/Users/UserDetails/Support/Support";

/**
 * User Dashboard Routes
 * Defines the routes for managing user dashboard-related functionalities, such as profile, activity, feedback, etc.
 *
 * Features:
 * 1. Includes routes for user-specific actions like ratings, profile, notifications, and subscriptions.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const userDashboardRoutes = [
  {
    id: "userdash1", // Unique route identifier
    name: "Rating List", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/rating", // URL path for the rating list
    component: RatingPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Rating List", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userdash2", // Unique route identifier
    name: "Profile", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/profile", // URL path for the profile page
    component: ProfilePage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Profile", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userdash3", // Unique route identifier
    name: "Activities", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/activity", // URL path for the activity page
    component: ActivityPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Activity", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userdash4", // Unique route identifier
    name: "Support", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/support", // URL path for the support page
    component: Support, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Support", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userdash5", // Unique route identifier
    name: "Feedback", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/feedback", // URL path for the feedback page
    component: FeedbackPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Feedback", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userdash6", // Unique route identifier
    name: "Subscription", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/subscription", // URL path for the subscription page
    component: Subscription, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Subscription", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userdash7", // Unique route identifier
    name: "Notification", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:id/notification", // URL path for the notification page
    component: Notification, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "Notification", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

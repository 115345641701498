// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the SCA component
import SCA from "../../Pages/SCA/SCA";

/**
 * Self Check Audit (SCA) Routes
 * Defines the route for managing Self Check Audit (SCA) in the application.
 *
 * Features:
 * 1. Includes a route for viewing and managing SCA.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const scaRoutes = [
  {
    id: "SCA", // Unique route identifier
    name: "Self Check Audit", // Display name for the route
    module: "SCA", // Associated module name
    path: "/sca", // URL path for the SCA page
    component: SCA, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Self Check Audit", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

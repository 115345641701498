import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import PaginationComponent from "../../Components/PaginationComponent";
import TableComponent from "../../Components/TableComponent";
import { AdminTypeListTableHeader } from "../../utils/TableColumns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  DeleteUserAPI,
  UserPostAPI,
  UserListAPI,
  UserListSort,
  handleSort,
  Status,
} from "../../Redux/features/UserManagementSlice";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import moment from "moment";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import Sidedrawer from "../../Components/SideDrawer";
import AddAdminForm from "../../Containers/UserManagement/AddAdminForm";
import InputComponent from "../../Components/Inputs/InputComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { manageSorting } from "../../utils/helper";
import { useIsMount } from "../../CustomHooks/useIsMount";

type Props = {};

type FormValues = {
  email: string;
  first_name: string;
  last_name: string;
  status: boolean;
};

const AdminListPage = (props: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    first_name: Yup.string().required("Name is required"),
    last_name: Yup.string().required("Name is required"),
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "", last_name: "", first_name: "", status: true },
  });

  const dispatch = useAppDispatch();

  const [adminListValue, setAdminListValue] = useState();
  const [userName, setuserName] = useState("userName");
  const [showUpdateForm, setUpdateForm] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [showAddForm, setAddFormModal] = useState(false);
  const [perPage, setPerPage] = useState<{ label: string; value: number }>({
    label: "Show 50",
    value: 50,
  });

  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );
  const [id, setid] = useState(0);

  const userList = useAppSelector(
    (state) => state.UserManagement.userList.data
  );
  const pageNumber = useAppSelector(
    (state) => state.UserManagement.userList.current_page
  );
  const sortType = useAppSelector((state) => state.UserManagement.sortType);

  useEffect(() => {
    dispatch(
      UserListAPI({
        page: 1,
        userType: "admin",
        sortType: sortType,
        sortValue: sortValue,
        per_page: perPage.value,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const handleEditClick = (
    id: number,
    first: string,
    last: string,
    email: string
  ) => {
    setUpdateForm(true);
    setValue("email", email);
    setValue("first_name", first);
    setValue("last_name", last);
    setid(id);
  };

  const handleDeleteClick = (id: number, username: string) => {
    setDeleteForm(true);
    setid(id);
    setuserName(username);
  };

  const ConfirmDelete = () => {
    dispatch(DeleteUserAPI(id)).then((res: any) => {
      if (res.payload.success) {
        setDeleteForm(false);
        dispatch(
          UserListAPI({
            page: pageNumber,
            userType: "admin",
            per_page: perPage.value,
          })
        );
      }
    });
  };

  const ConfirmUpdate = (data: FormValues) => {
    dispatch(UserPostAPI({ data: data, id: id })).then((res: any) => {
      if (res.payload?.success) {
        setUpdateForm(false);
        dispatch(
          UserListAPI({
            page: pageNumber,
            userType: "admin",
            per_page: perPage.value,
          })
        );
      }
    });
  };

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const handleCloseUpdateModel = () => {
    setUpdateForm(false);
  };

  const formValues = watch();

  const sortValue = useAppSelector((state) => state.UserManagement.sortValue);
  const active = useAppSelector(
    (state) => state.UserManagement.userList?.current_page
  );

  const total = useAppSelector(
    (state) => state.UserManagement.userList?.last_page
  );

  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      UserListAPI({
        page: pageNumber,
        userType: "admin",
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: perPage.value,
      })
    );
  };

  const listLoading = useAppSelector(
    (state) => state.UserManagement.UserListAPIIdle
  );

  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  const setPerPageValue = (value: { label: string; value: number }) => {
    setPerPage(value);
    dispatch(
      UserListAPI({
        page: 1,
        userType: "admin",
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: value.value,
      })
    );
  };
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          UserListAPI({
            page: 1,
            userType: "admin",
            searchValue: adminListValue,
            sortType: sortType,
            sortValue: sortValue,
            per_page: perPage.value,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [adminListValue]);

  const getSortValue = () => {
    dispatch(UserListSort());
  };

  useEffect(() => {
    document.title = "FISIO | List Admin";
  }, []);
  return (
    <>
      <BoxLayout HeaderName="Admin Management" sectionName="Admin List">
        <FilterSortPanel
          Function={() => setAddFormModal((prev) => !prev)}
          showAddForm={showAddForm}
          getSearchValue={getSearchValue}
          getSortValue={getSortValue}
          sortType={sortType}
        >
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[99]">
            <Sidedrawer onClose={setAddFormModal}>
              <AddAdminForm setAddFormModal={setAddFormModal} />
            </Sidedrawer>
          </div>
        </FilterSortPanel>
        <TableComponent
          onClick={manageSorting}
          loader={listLoading}
          TableHeaderData={AdminTypeListTableHeader}
          sortValue={sortValue}
          sortType={sortType}
          handleSortAction={handleSort}
        >
          {listLoading === Status.SUCCESS && (
            <tbody className="">
              {userList.map((i) => (
                <tr
                  className="border-y py-1 text-xs border-y-[#EAF0F3]"
                  key={i._id}
                >
                  <td className="py-1 text-start font-medium cursor-pointer text-[#212121] ">
                    {i.userid}
                  </td>
                  <td className="py-1 text-start font-medium   text-[#212121]">
                    {i.first_name + " " + i.last_name}
                  </td>
                  <td className="py-1 text-start text-[#767576] capitalize ">
                    {i.user_type}
                  </td>

                  <td className="py-1 text-start text-[#767576]">
                    {i.is_confirm === 1 ? "Yes" : "No"}
                  </td>
                  <td className="py-1 text-start text-[#767576]">{i.email}</td>
                  <td className="py-1 text-start text-[#767576]">
                    {moment(i.registered_at).format("DD.MM.YYYY HH:mm")}
                  </td>
                  <td className="py-1 text-start text-[#767576]">
                    {moment(i.last_login_at).format("DD.MM.YYYY HH:mm")}
                  </td>
                  <td className="py-1 flex gap-3 text-start">
                    <button
                      onClick={() =>
                        handleEditClick(
                          i.userid,
                          i.first_name,
                          i.last_name,
                          i.email
                        )
                      }
                    >
                      <EditIcon />
                    </button>
                    <button
                      onClick={() =>
                        handleDeleteClick(
                          i.userid,
                          i.first_name + " " + i.last_name
                        )
                      }
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {listLoading === Status.LOADING && <TableSkeleton tableColumns={9} />}
        </TableComponent>
        <PaginationComponent
          paginationFunction={pageChange}
          active={active}
          total={total}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
        />
      </BoxLayout>

      <ModalComponent
        confirmAction={ConfirmDelete}
        message={`Are you sure you want to disable ${userName}?`}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
      />

      {showUpdateForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[99]">
          <Sidedrawer onClose={handleCloseUpdateModel}>
            <p className="text-lg p-4">Update User</p>
            <form
              className="p-4 flex  h-full flex-col justify-between gap-4"
              onSubmit={handleSubmit(ConfirmUpdate)}
            >
              <div className="h-full flex flex-col gap-4">
                <InputComponent
                  register={register}
                  inputRef="email"
                  label="email"
                  name="email"
                  errorname={errors.email?.message}
                  value={formValues.email}
                />
                <InputComponent
                  register={register}
                  inputRef="first_name"
                  label="First Name"
                  name="first_name"
                  errorname={errors.first_name?.message}
                  value={formValues.first_name}
                />
                <InputComponent
                  register={register}
                  inputRef="last_name"
                  label="Last Name"
                  name="last_name"
                  errorname={errors.last_name?.message}
                  value={formValues.last_name}
                />
              </div>
              <div className="flex h-[150px] gap-6">
                <ButtonComponent
                  buttonType="submit"
                  CTA="Update"
                  varient={"blue"}
                  loading={loading}
                />
                <ButtonComponent
                  buttonType="button"
                  CTA="cancel"
                  onClick={handleCloseUpdateModel}
                />
              </div>
            </form>
          </Sidedrawer>
        </div>
      )}
    </>
  );
};

export default AdminListPage;

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the onboarding-related pages
import AddOnBoardingQuestionPage from "../../Pages/OnBoardingQuestion/AddOnBoardingQuestionPage";
import EditOnBoardingQuestionPage from "../../Pages/OnBoardingQuestion/EditOnBoardingQuestionPage";
import OnboardingQuestionsPage from "../../Pages/OnBoardingQuestion/OnboardingQuestionsPage";
import AboutFisio from "../../Pages/WelcomeMarketing/AboutFisio/AboutFisio";
import WelcomeMarketing from "../../Pages/WelcomeMarketing/AddWelcomeMarketing";

/**
 * Onboarding Routes
 * Defines the routes for managing onboarding questions and welcome screens.
 *
 * Features:
 * 1. Includes routes for viewing, editing, and adding onboarding questions.
 * 2. Provides routes for managing welcome screens and "About Fisio".
 * 3. Uses lazy-loaded components to optimize initial load performance.
 * 4. Breadcrumbs are included for navigation clarity.
 */
export const onBoardingRoutes = [
  {
    id: "onboarding1", // Unique route identifier
    name: "Onboarding Questions Repository", // Display name for the route
    module: "On-Boarding Questions", // Associated module name
    path: "/onboardingquestionsrepository", // URL path for the onboarding questions page
    component: OnboardingQuestionsPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "OnBoarding Questions", // Breadcrumb name
        path: "/onboardingquestionsrepository", // Path for breadcrumb
      },
    ],
  },
  {
    id: "onboarding2", // Unique route identifier
    name: "Edit Questions List", // Display name for the route
    module: "On-Boarding Questions", // Associated module name
    path: "/onboardingquestionsrepository/editonboardquestions", // URL path for editing onboarding questions
    component: EditOnBoardingQuestionPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "OnBoarding Questions", // Parent breadcrumb
        path: "/onboardingquestionsrepository", // Path for breadcrumb
      },
      {
        name: "Edit Questions", // Current page breadcrumb
        path: "/onboardingquestionsrepository/editonboardingquestions", // Path for breadcrumb
      },
    ],
  },
  {
    id: "onboarding3", // Unique route identifier
    name: "Add Question", // Display name for the route
    module: "On-Boarding Questions", // Associated module name
    path: "/onboardingquestionsrepository/addonboardquestions", // URL path for adding onboarding questions
    component: AddOnBoardingQuestionPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "OnBoarding Questions", // Parent breadcrumb
        path: "/onboardingquestionsrepository", // Path for breadcrumb
      },
      {
        name: "Add Question", // Current page breadcrumb
        path: "/onboardingquestionsrepository/addonboardquestions", // Path for breadcrumb
      },
    ],
  },
  {
    id: "onboarding4", // Unique route identifier
    name: "Manage Welcome Screen", // Display name for the route
    module: "On-Boarding Questions", // Associated module name
    path: "/managewelcomescreen", // URL path for managing the welcome screen
    component: WelcomeMarketing, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Manage Welcome Screen", // Breadcrumb name
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "onboarding5", // Unique route identifier
    name: "About Fisio", // Display name for the route
    module: "On-Boarding Questions", // Associated module name
    path: "/aboutfisio", // URL path for the "About Fisio" page
    component: AboutFisio, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "FTUE", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "About Fisio", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./routes/index";
import { ToastContainer } from "react-toastify";
import "./App.css";

function App() {
  const isProd = process.env?.REACT_APP_ISPROD;

  return (
    <BrowserRouter>
      <ToastContainer />
      {isProd === "FALSE" ? (
        <div className="bg-red-500 h-3 text-center z-[9999] inline-block fixed top-0 w-full text-white text-[8px]">
          QA version
        </div>
      ) : (
        <></>
      )}
      <div className="bg-secondary">
        <Routes>
          {AppRoutes.map((i) => (
            <Route
              key={i.id}
              path={i.path}
              element={
                <i.auth>
                  <i.component />
                </i.auth>
              }
            />
          ))}
        </Routes>
        <span className="px-1 text-blue-950 text-xs fixed left-0 right-0 z-30 bottom-0"></span>
      </div>
    </BrowserRouter>
  );
}

export default App;

import { useDispatch } from "react-redux";
import TableComponent from "../../Components/TableComponent";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  userListHandleSort,
  Status,
  addCheckbox,
  removeCheckbox,
} from "../../Redux/features/UserManagementSlice";
import {
  AddDrillTableHeader,
  CoachesListTableHeader,
} from "../../utils/TableColumns";
import { manageSorting } from "../../utils/helper";
import { Dispatch, useEffect, useState } from "react";
import PaginationComponent from "../../Components/PaginationComponent";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import EditIcon from "../../Components/Icons/EditIcon";
import AlignIcon from "../../Components/Icons/AlignIcon";
import {
  getCoachListAPI,
  handleSort,
  ticketListHandleSort,
} from "../../Redux/features/CoachReviewSlice";
import { useIsMount } from "../../CustomHooks/useIsMount";
import moment from "moment";

type Props = {};

const CoachReviewPage = (props: Props) => {
  const coachList = useAppSelector((state) => state.Coach.coachList);
  const listLoading = useAppSelector(
    (state) => state.Coach.getCoachListAPIIdle
  );
  const dispatch = useAppDispatch();
  const sortType = useAppSelector((state) => state.Coach.ticketListsortType);
  const sortValue = useAppSelector((state) => state.Coach.ticketListsortValue);
  const active = useAppSelector((state) => state.Coach.active);
  const total = useAppSelector((state) => state.Coach.total);
  const [searchValue, setSearchValue] = useState("");
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getCoachListAPI({
            page: 1,
            userType: "admin",
            searchValue: searchValue,
            sortType: sortType,
            sortValue: sortValue,
          })
        ).then((response) => {});
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [searchValue]);

  useEffect(() => {
    dispatch(
      getCoachListAPI({
        page: 1,
        userType: "admin",
        sortType: sortType,
        sortValue: sortValue,
      })
    ).then((response) => {});
    return () => {
      // dispatch(clearUserList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getCoachListAPI({
        page: pageNumber,
        userType: "user",
        searchValue: searchValue,
        sortType: sortType,
        sortValue: sortValue,
      })
    ).then((response) => {});
  };
  const getSearchValue = (d: string) => {
    setSearchValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  return (
    <>
      <BoxLayout HeaderName="Coach Review" sectionName="Coaches List">
        <FilterSortPanel
          Function={() => {}}
          getSearchValue={getSearchValue}
          showAddButton={false}
          exportButton={<AlignIcon />}
        />
        <TableComponent
          TableHeaderData={CoachesListTableHeader}
          loader={listLoading}
          onClick={manageSorting}
          sortType={sortType}
          sortValue={sortValue}
          handleSortAction={ticketListHandleSort}
          sortIcon
        >
          {listLoading === Status.SUCCESS && (
            <tbody className="">
              {coachList.map((data, index) => (
                <tr
                  className="cursor-pointer py-1 border-y border-y-[#EAF0F3]"
                  key={index}
                >
                  <td className="py-3 text-start pr-1  ">{data.full_name}</td>
                  <td className="py-3 text-[#767576] pr-1 flex ">
                    {/* <p className="w-[40px]">{data?.total_hours}</p>{" "} */}
                    <button onClick={() => ""}>
                      <EditIcon />
                    </button>
                  </td>
                  <td className="py-3 text-[#767576] pr-1 ">
                    {/* {data.total_completed_tickets} */}
                  </td>
                  <td className="py-3 text-[#767576] pr-1">
                    {/* {data.avg_response_time} */}
                  </td>
                  <td className="py-3 text-[#767576] pr-1 ">
                    {moment(data.created_at).format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td className="py-3 text-[#767576] pr-1">
                    {data.last_login_at}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {listLoading === Status.LOADING && <TableSkeleton tableColumns={6} />}
        </TableComponent>
        <PaginationComponent
          active={active}
          total={total}
          paginationFunction={pageChange}
        />
      </BoxLayout>
    </>
  );
};

export default CoachReviewPage;

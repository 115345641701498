import React from "react";
import { useForm } from "react-hook-form";
import InputComponent from "../../Components/Inputs/InputComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { AddTagAPI, TagListAPI } from "../../Redux/features/TagSlice";
import { Status } from "../../Redux/features/UserManagementSlice";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  name: string;
  type: string;
};

const AddNewTagForm = ({ setAddFormModal }: Props) => {
  const validationSchema = Joi.object({
    name: Joi.string().min(2).required().messages({
      "string.min": "Tag Name must be at least 2 characters",
      "any.required": "Tag Name is required",
    }),
    type: Joi.string().min(2).required().messages({
      "string.min": "Tag Type must be at least 2 characters",
      "any.required": "Tag Type is required",
    }),
  });

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
  });

  const dispatch = useAppDispatch();
  const tagSortType = useAppSelector((state) => state.Tag.sortType);
  const onSubmit = async (data: FormValues) => {
    dispatch(
      AddTagAPI({
        name: data.name,
        type: data.type,
      })
    ).then((res: any) => {
      setAddFormModal(false);
      dispatch(TagListAPI({ sortType: tagSortType }));
    });
  };

  const formValues = watch();

  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );

  return (
    <div className="p-4 flex flex-col h-screen">
      <h2 className="text-xl font-bold mb-4">Add New Tag</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 flex h-full  flex-col gap-3 justify-between"
      >
        <div className="flex flex-col gap-5">
          <InputComponent
            errorname={errors.name?.message}
            inputRef="name"
            label="Tag Name"
            name="name"
            register={register}
            value={formValues.name}
          />
          <SelectComponent
            errorname={errors.type?.message}
            inputRef="type"
            label="Tag Type"
            control={control}
            options={[
              {
                value: "Users ",
                label: "Users",
              },
              {
                value: "Content",
                label: "Content",
              },
            ]}
            asterisk
          />
        </div>
        <div className="flex rounded-none col-end-3 ">
          <button
            disabled={loading === Status.LOADING}
            className="bg-bluegradient text-white rounded-md px-4 py-2 mr-2 w-1/2"
          >
            {loading === Status.LOADING ? "Submitting" : "Save"}
          </button>
          <button
            className="border-2  px-4 py-2 rounded w-1/2"
            onClick={() => setAddFormModal((prev) => !prev)}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewTagForm;

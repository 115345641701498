import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { useAppSelector } from "../Redux/app/hooks";
import MultiSelectComponent from "./Inputs/MultiSelectComponent";

type Props = {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  formValues: any;
  control: Control<any>;
  showMenuFull?: boolean;
};

const AddObjectTag = ({ control, showMenuFull = false }: Props) => {
  const options = useAppSelector((state) => state.Tag.TagList.data.tags);

  return (
    <div className="m-4">
      <MultiSelectComponent
        showMenuFull={showMenuFull}
        isMulti={true}
        control={control}
        inputRef="tag"
        label="Tags"
        options={options?.map((value) => {
          return {
            value: value._id,
            label: value.name,
          };
        })}
      />
    </div>
  );
};

export default AddObjectTag;

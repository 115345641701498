// Import necessary modules for lazy loading and authentication
// For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the user management-related pages
import AdminListPage from "../../Pages/Users/AdminListPage";
import UserIdDetail from "../../Pages/Users/UserDetails/UserIdDetail";
import UserListPage from "../../Pages/Users/UserListPage";

/**
 * User Management Routes
 * Defines the routes for managing users and admins in the User Management and User Dashboard modules.
 *
 * Features:
 * 1. Includes routes for admin list, user list, and user detail views.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const usermanagementRoutes = [
  {
    id: "userManage1", // Unique route identifier
    name: "Admin List", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/adminlist", // URL path for the admin list route
    component: AdminListPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Admin Management", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "Admin List", // Current page breadcrumb
        path: "/usermanagement/adminlist", // Path for breadcrumb
      },
    ],
  },
  {
    id: "userManage2", // Unique route identifier
    name: "User List", // Display name for the route
    module: "User Dashboard", // Associated module name
    path: "/usermanagement/userlist", // URL path for the user list route
    component: UserListPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Dashboard", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
  {
    id: "userManage3", // Unique route identifier
    name: "User Details", // Display name for the route
    module: "User Management", // Associated module name
    path: "/usermanagement/userlist/:userId", // URL path for the user details route
    component: UserIdDetail, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "User Management", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "User List", // Breadcrumb for the user list page
        path: "/usermanagement/userlist", // Path for breadcrumb
      },
      {
        name: "User Details", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];

// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the unit-related pages
import AddUnitsPage from "../../Pages/Units/AddUnitsPage";
import EditUnitsPage from "../../Pages/Units/EditUnitPage";
import UnitListPage from "../../Pages/Units/UnitsListPage";

/**
 * Unit Routes
 * Defines the routes for managing units in the Content Repository module.
 *
 * Features:
 * 1. Includes routes for viewing, adding, and editing units.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const unitRoutes = [
  {
    id: "unit1", // Unique route identifier
    name: "Unit List", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/units", // URL path for the unit list route
    component: UnitListPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Unit List", // Breadcrumb name
        path: "/contentrepository/units", // Path for breadcrumb
      },
    ],
  },
  {
    id: "unit2", // Unique route identifier
    name: "Add Unit", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/AddUnits", // URL path for the add unit route
    component: AddUnitsPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Unit List", // Parent breadcrumb
        path: "/contentrepository/units", // Path for breadcrumb
      },
      {
        name: "Add Unit", // Current page breadcrumb
        path: "/contentrepository/AddUnits", // Path for breadcrumb
      },
    ],
  },
  {
    id: "unit3", // Unique route identifier
    name: "Edit Units", // Display name for the route
    module: "Content Repository", // Associated module name
    path: "/contentrepository/editunit", // URL path for the edit unit route
    component: EditUnitsPage, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "Unit List", // Parent breadcrumb
        path: "/contentrepository/units", // Path for breadcrumb
      },
      {
        name: "Edit Unit", // Current page breadcrumb
        path: "/contentrepository/editunits", // Path for breadcrumb
      },
    ],
  },
];

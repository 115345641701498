// Import necessary modules for lazy loading and authentication
import { lazy } from "react"; // For lazy loading components
import AdminRoutes from "../../auth/AdminRoutes"; // Admin authentication wrapper

// Lazy load the FAQ page
import FAQ from "../../Pages/Faq/Faq";

/**
 * FAQ Routes
 * Defines the route for managing Frequently Asked Questions (FAQs) in the application.
 *
 * Features:
 * 1. Includes a route for viewing and managing FAQs.
 * 2. Uses lazy-loaded components to optimize initial load performance.
 * 3. Breadcrumbs are included for navigation clarity.
 */
export const faqRoutes = [
  {
    id: "FAQ", // Unique route identifier
    name: "FAQs", // Display name for the route
    module: "FAQs", // Associated module name
    path: "/faq", // URL path for the FAQ page
    component: FAQ, // Lazy-loaded component
    auth: AdminRoutes, // Admin authentication wrapper
    breadCrumbRoutes: [
      {
        name: "FAQs", // Parent breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
      {
        name: "All Questions", // Current page breadcrumb
        path: "#", // Path for breadcrumb (stub)
      },
    ],
  },
];
